const advertisements = props => {
    return {
        base: {
            entities: "کسب و کارهای برگزیده",
            entity: "کسب و کار برگزیده",
            module: "selected_services",
            path: "/selected_services",
            model: {
                service_id: {
                    title: "کسب و کار",
                    type: "picker",
                    module: "services",
                    path: "businesses",
                    caption: "service_title"
                },
                month: {title: "ماه"},
                year: {title: "سال"},
                description: {title: "توضیحات"},
            }
        },
        list: {
            page: props.page,
            fields: [
                {name: "service_id"},
                {name: "month"},
                {name: "year"},
                {name: "description"},
            ],
            operations: ["add", "edit", "remove"]
        },
        item: {
            id: props.id,
            fields: [
                {name: "service_id"},
                {name: "month"},
                {name: "year"},
                {name: "description"},
            ]
        }
    };
};

export default advertisements;