const complexFloors = props => {
    return {
        base: {
            entities: "طبقات " + props.complex_name,
            entity: "طبقه",
            module: "complex_floors",
            path: "/complex_floors",
            model: {
                image: {title: "پلن طبقه", type: "image"},
                name: {title: "نام"},
                order: {title: "ترتیب"},
            }
        },
        list: {
            page: props.page,
            default_conditions: {complex_id: props.complex_id},
            custom_add: {
                className: "primary",
                caption: "افزودن طبقه جدید",
                click: {
                    func: props.add,
                }
            },
            custom_edit: {
                click: {
                    func: props.edit,
                }
            },
            fields: [
                {name: "image"},
                {name: "name"},
                {name: "order"},
            ],
            operations: ["add", "edit", "remove"]
        },
        item: {
            id: props.id,
            custom_add_title: "افزودن طبقه جدید به " + props.complex_name,
            custom_edit_title: "ویرایش طبقه در " + props.complex_name,
            initial_data: {
                complex_id: props.complex_id
            },
            fields: [
                {name: "image"},
                {name: "name"},
                {name: "order"},
                {name: "complex_id", type: "hidden"},
            ]
        }
    };
};

export default complexFloors;