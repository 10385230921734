const achievements = props => {
    return {
        base: {
            entities: "دریافتی ها",
            entity: "دریافتی",
            module: "achievements",
            path: "/achievements",
            model: {
                id: {title: "آیدی"},
                coins: {title: "سکه"},
                title: {title: "عنوان"},
                description: {title: "توضیحات"},
                medal: {title: "مدال", type: "image"},
                group: {title: "گروه"},
                has_medal: {
                    title: "مدال دارد",
                    type: "select",
                    items: [
                        {key: true, value: "دارد"},
                        {key: false, value: "ندارد"}
                    ]
                }
            }
        },
        list: {
            page: props.page,
            fields: [
                {name: "title"},
                {name: "description"},
                {name: "group"},
                {name: "coins"},
                {name: "has_medal"},
                {name: "medal"},
            ],
            operations: ["add", "edit", "remove"]
        },
        item: {
            id: props.id,
            fields: [
                {name: "id"},
                {name: "title"},
                {name: "description"},
                {name: "group"},
                {name: "coins"},
                {name: "has_medal"},
                {name: "medal"},
            ],
        },
    };
};

export default achievements;