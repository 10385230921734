const serverNotification = props => {
    return {
        base: {
            entities: "نوتیفیکیشن ها",
            entity: "نوتیفیکیشن",
            module: "server_notifications",
            path: "/server_notifications",
            model: {
                group: {title: "گروه"},
                name: {title: "نام"},
                title: {title: "عنوان"},
                description: {title: "توضیحات"},
            }
        },
        list: {
            page: props.page,
            fields: [
                {name: "group"},
                {name: "name"},
                {name: "title"},
                {name: "description"},
            ],
            operations: ["add", "edit", "remove"]
        },
        item: {
            id: props.id,
            fields: [
                {name: "group"},
                {name: "name"},
                {name: "title"},
                {name: "description", type: "textarea", rows: 4},
            ]
        }
    };
};

export default serverNotification;