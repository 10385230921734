const pins = props => {
    return {
        base: {
            entities: "پین ها",
            entity: "پین",
            module: "pins",
            path: "/pins",
            model: {
                name: {title: "ماژول"},
                pin: {title: "پین", type: "image", width: 45, height: 45},
                pin_hover: {title: "پین انتخاب شده", type: "image", width: 45, height: 45},
            }
        },
        list: {
            page: props.page,
            fields: [
                {name: "name"},
                {name: "pin"},
                {name: "pin_hover"},
            ],
            operations: ["add", "edit", "remove"]
        },
        item: {
            id: props.id,
            fields: [
                {name: "name"},
                {name: "pin"},
                {name: "pin_hover"},
            ]
        }
    };
};

export default pins;