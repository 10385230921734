const bannedPhrase = props => {
    return {
        base: {
            entities: "عبارات ممنوعه",
            entity: "عبارت ممنوعه",
            module: "banned_phrases",
            path: "/banned_phrases",
            model: {
                text: {title: "عبارت"},
            },
        },
        list: {
            page: props.page,
            fields: [
                {name: "text"},
            ],
            search: [
                {
                    name: "text",
                    placeholder: "عبارت",
                },
            ],
            operations: ["add", "edit", "remove"]
        },
        item: {
            id: props.id,
            fields: [
                {name: "text"},
            ],
        },
    };
};

export default bannedPhrase;