const server_sms = props => {
    return {
        base: {
            entities: "پیامک ها",
            entity: "پیامک",
            module: "server_sms",
            path: "/server_sms",
            model: {
                group: {title: "گروه"},
                name: {title: "نام"},
                text: {title: "متن"},
            }
        },
        list: {
            page: props.page,
            fields: [
                {name: "group"},
                {name: "name"},
                {name: "text"},
            ],
            operations: ["add", "edit", "remove"]
        },
        item: {
            id: props.id,
            fields: [
                {name: "group"},
                {name: "name"},
                {name: "text"},
            ]
        }
    };
};

export default server_sms;