const postLikes = props => {
    return {
        base: {
            entities: "لایک ها",
            entity: "لایک",
            module: "post_likes",
            path: "/post_likes",
            model: {
                user_id: {title: "لایک کننده"}
                // user_id: {
                //     title: "لایک کننده",
                //     type: "foreign",
                //     foreign: {
                //         module: "users",
                //         path: "/users",
                //         field: "id",
                //         result: [
                //             {name: "name"},
                //             {type: "static", value: " "},
                //             {name: "family"}
                //         ]
                //     }
                // },
            }
        },
        list: {
            page: props.page,
            default_conditions: {post_id: props.id},
            fields: [
                {name: "user_id"},
            ],
            operations: []
        },
    };
};

export default postLikes;