const user_contacts = props => {
    return {
        base: {
            entities: "کانتکت ها",
            entity: "کانتکت",
            module: "user_contacts",
            path: "/user_contacts",
            model: {
                name: {title: "نام"},
                tel: {title: "تلفن"},
                group_id: {
                    title: "گروه",
                    type: "picker",
                    module: "user_contact_groups",
                    path: "user_contact_groups",
                    caption: ["name"]
                },
                user_id: {
                    title: "کاربر",
                    type: "picker",
                    module: "users",
                    path: "users",
                    caption: ["user_name"]
                },
            }
        },
        list: {
            page: props.page,
            fields: [
                {name: "name"},
                {name: "tel"},
                {name: "user_id"},
                {name: "group_id"},
            ],
            operations: ["add", "edit", "remove"]
        },
        item: {
            id: props.id,
            fields: [
                {name: "name"},
                {name: "tel"},
                {name: "user_id"},
                {name: "group_id"},
            ]
        }
    };
};

export default user_contacts;