import React, {useEffect, useState} from "react";
import {Loading, xhr} from "../components";
import {Tabs, Tab, Table} from "react-bootstrap";
import {Link} from "react-router-dom";

export default function LogsReport() {
    const [report, setReport] = useState(null);

    useEffect(() => {
        new xhr({
            url: "customlog",
            server: "log"
        }).GetMany(response => {
            if(response.status){
                setReport(response.data.item);
            }
        });
    }, []);

    return <div>
        <h2>گزارشات لاگ ها</h2>
        {report ?
            <Tabs>
                <Tab eventKey="ips" title="IP ها">
                    <Table>
                        <thead>
                        <tr>
                            <th>IP</th>
                            <th>تعداد</th>
                        </tr>
                        </thead>
                        <tbody>
                        {report.ips &&
                            report.ips.map(ip => <tr>
                                <tr>{ip.ip}</tr>
                                <tr>{ip.count}</tr>
                            </tr>)
                        }
                        </tbody>
                    </Table>
                </Tab>
                <Tab eventKey="requests" title="درخواست های کاربران">
                    <Table>
                        <thead>
                        <tr>
                            <th>کاربر</th>
                            <th>موبایل</th>
                            <th>تعداد</th>
                        </tr>
                        </thead>
                        <tbody>
                        {report.user_ids &&
                            report.user_ids.map(user => <tr>
                                <td><Link to={"/users/edit/" + user.user_id} target="_blank">{user.name + ' ' + user.family}</Link></td>
                                <td>{user.mobile}</td>
                                <td>{user.count}</td>
                            </tr>)
                        }
                        </tbody>
                    </Table>
                </Tab>
                <Tab eventKey="time" title="زمان اجرای درخواست ها">
                    <Table>
                        <thead>
                        <tr>
                            <th>ماژول</th>
                            <th>زمان کل</th>
                            <th>تعداد</th>
                            <th>میانگین زمان</th>
                        </tr>
                        </thead>
                        <tbody>
                        {report.duration &&
                            report.duration.map(duration => <tr>
                                <td>{duration.module}</td>
                                <td>{Math.round(duration.duration / 100)} ثانیه</td>
                                <td>{duration.count}</td>
                                <td>{Math.round(duration.avg_duration / 100)} ثانیه</td>
                            </tr>)
                        }
                        </tbody>
                    </Table>
                </Tab>
            </Tabs>
            :
            <Loading/>
        }
    </div>;
}