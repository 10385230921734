const user_contact_groups = props => {
    return {
        base: {
            entities: "گروه کانتکت ها",
            entity: "گروه کانتکت",
            module: "user_contact_groups",
            path: "/user_contact_groups",
            model: {
                user_id: {
                    title: "کاربر",
                    type: "picker",
                    module: "users",
                    path: "users",
                    caption: ["user_name"]
                },
                name: {title: "توضیحات"},
            }
        },
        list: {
            page: props.page,
            fields: [
                {name: "user_id"},
                {name: "name"},
            ],
            operations: ["add", "edit", "remove"]
        },
        item: {
            id: props.id,
            fields: [
                {name: "user_id"},
                {name: "name"},
            ]
        }
    };
};

export default user_contact_groups;