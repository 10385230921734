const wallet = props => {
    return {
        base: {
            entities: "کیف پول",
            entity: "اعتبار",
            module: "wallet",
            path: "/wallet",
            model: {
                user_id: {
                    title: "کاربر",
                    type: "picker",
                    module: "users",
                    caption: "user_id"
                },
                coins: {title: "سکه", type: "formatted_number"},
                title: {title: "عنوان"},
                description: {title: "توضیحات"},
            }
        },
        list: {
            page: props.page,
            fields: [
                {name: "user_id"},
                {name: "coins", style: {direction: "ltr"}},
                {name: "title"},
                {name: "description"},
                {name: "create_date", title: "تاریخ", type: "date"},
                {name: "create_date", title: "زمان", type: "time"},
            ],
            operations: ["add", "edit", "remove"]
        },
        item: {
            id: props.id,
            fields: [
                {name: "user_id"},
                {name: "coins", style: {direction: 'ltr', textAlign: 'left'}},
                {name: "title"},
                {name: "description"},
            ]
        }
    };
};

export default wallet;