const feedback = props => {
    return {
        base: {
            entities: "بازخوردها",
            entity: "بازخورد",
            module: "feedback",
            path: "/feedback",
            model: {
                name: {title: "نام"},
                comment: {title: "نظر"},
                mobile: {title: "موبایل"},
                email: {title: "ایمیل"},
            }
        },
        list: {
            page: props.page,
            fields: [
                {name: "name"},
                {name: "comment"},
                {name: "mobile"},
                {name: "email"},
                {name: "create_date", title: "تاریخ", type: "date"},
                {name: "create_date", title: "زمان", type: "time"},
            ],
            operations: ["remove"]
        },
    };
};

export default feedback;