import React, { useContext, useState } from "react";
import {Form, Button, Alert} from "react-bootstrap";
import {xhr, Loading} from "../components/";
import {Context} from "../providers/mainProvider";
import {useHistory} from "react-router-dom";
import Cookies from "../components/cookies";

export default function Login() {
    const {setValue, fullName, config} = useContext(Context);
    const [message, setMessage] = useState(null);
    const [loading, setLoading] = useState(false);
    const [item, setItem] = useState({
        code: '+98',
        mobile: '',
        password: '',
        selectServer: config.SERVERS.base,
        server: config.SERVERS.base,
    });
    const history = useHistory();
    let testMode = false;
    let {hostname} = window.location;
    if(config.TEST_URL.indexOf(hostname) > -1){
        testMode = true;
    }

    const handleChange = e => {
        let {target} = e;
        if(target.name === 'selectServer'){
            setItem(item.changeBatch({
                selectServer: target.value,
                server: target.value
            }));
        }else{
            setItem(item.change(target.name, target.value));
        }
    };

    const handleSubmit = e => {
        e.preventDefault();
        setLoading(true);
        let data = {...item};
        localStorage.setItem('baseURL', data.server);
        delete data['selectServer'];
        delete data['server'];
        new xhr({
            url: 'login',
            data,
            server: data.server,
            no_locale: true,
            version: 'v2.1'
        }).Post(response => {
            setLoading(false);
            if(response.status){
                setValue({
                    auth: true,
                    user_id: response.data.item.id
                });
                const CookieObj = new Cookies();
                CookieObj.setCookieBatch([
                    {
                        name: "token",
                        value: response.token,
                        days: 7
                    },
                    {
                        name: "UID",
                        value: response.data.item.id,
                        days: 7
                    },
                    {
                        name: "UNAME",
                        value: fullName(response.data.item.name, response.data.item.family),
                        days: 7
                    }
                ]);
                localStorage.setItem('allowed', JSON.stringify(response.data.item.allowed_urls));

                new xhr({url: 'admin_dashboard'}).GetMany(response => {
                    if(response.status){
                        setValue({
                            badgeItem: response.data.item
                        });
                        localStorage.setItem('badgeItem', JSON.stringify(response.data.item));
                    }
                });

                history.push("/");
            }else{
                setMessage(<Alert variant="danger">{response.note}</Alert>);
            }
        });
    };

    return <>
        <div className="login-box centered">
            {message}
            <Form noValidate onSubmit={handleSubmit}>
                {testMode &&
                    <>
                        <Form.Group controlId="selectServer">
                            <Form.Label>سرور</Form.Label>
                            <Form.Control
                                as="select"
                                name="selectServer"
                                value={item.selectServer}
                                onChange={handleChange}
                            >
                                {config.SERVERS_DROPDOWN.map(server =>
                                    <option value={server.key}>{server.value}</option>
                                )}
                            </Form.Control>
                        </Form.Group>
                        <Form.Group controlId="server">
                            <Form.Control
                                type="text"
                                name="server"
                                value={item.server}
                                onChange={handleChange}
                                bsPrefix="form-control ltr"
                            />
                        </Form.Group>
                    </>
                }
                <Form.Group controlId="mobile">
                    <Form.Label>نام کاربری</Form.Label>
                    <Form.Control
                        required
                        type="text"
                        name="mobile"
                        value={item.mobile}
                        onChange={handleChange}
                        bsPrefix="form-control ltr"
                    />
                </Form.Group>
                <Form.Group controlId="password">
                    <Form.Label>رمز عبور</Form.Label>
                    <Form.Control
                        required
                        type="password"
                        name="password"
                        value={item.password}
                        onChange={handleChange}
                        bsPrefix="form-control ltr"
                    />
                </Form.Group>
                <Form.Group controlId="submit">
                    <Button type="submit" disabled={loading}>{loading ? <Loading theme="light"/> : "ورود"}</Button>
                </Form.Group>
                {/*<Form.Group>*/}
                {/*    <div className="g-signin2" data-onsuccess="onSignIn"/>*/}
                {/*</Form.Group>*/}
            </Form>
        </div>
    </>;
}
