import React, {useEffect, useState} from "react";
import {Loading, xhr} from "../components";

export default function CategoriesReport() {
    const [categories, setCategories] = useState();
    const [loadingCat, setLoadingCat] = useState(false);
    const [counts, setCounts] = useState({});
    const [loadingBiz, setLoadingBiz] = useState({});

    const getCategories = () => {
        let cats = localStorage.getItem('categories');
        if(cats){
            setCategories(JSON.parse(cats));
            return;
        }
        setLoadingCat(true);
        new xhr({
            url: 'categories',
            data: {
                fields: JSON.stringify(['id', 'name']),
                conditions: {
                    parent_id: {$ne: ""}
                }
            },
            page: -1
        }).GetMany(response => {
            setLoadingCat(false);
            if(response.data){
                setCategories(response.data.list);
                localStorage.setItem('categories', JSON.stringify(response.data.list));
            }
        });
    };

    useEffect(getCategories, []);

    const getReport = () => {
        setCounts({});
        getBusinesses(0);
    };

    const getBusinesses = (index) => {
        let catId = categories[index].id;
        setLoadingBiz({...loadingBiz, [catId]: true});
        new xhr({
            url: 'services',
            data: {
                fields: JSON.stringify(['id']),
                conditions: {
                    "user.name": {$ne: "Admin"},
                    category_id: catId
                }
            },
            perPage: 1
        }).GetMany(response => {
            setLoadingBiz({...loadingBiz, [catId]: false});
            if(response.data){
                setCounts({...counts, [catId]: response.data.count});
            }
        });
    };

    useEffect(() => {
        let index = Object.keys(counts).length;
        if(index === 0) return;
        if(index < categories.length){
            getBusinesses(index);
        }else{
            localStorage.setItem('categories_biz_counts', JSON.stringify(counts));
        }
    }, [counts]);

    useEffect(() => {
        let cbc = localStorage.getItem('categories_biz_counts');
        if(cbc){
            setCounts(JSON.parse(cbc));
        }
    }, []);

    return (
        <div>
            <h2>گزارش دسته‌بندی‌ها</h2>
            <div style={{marginBottom: '10px'}}>
                <button className={'btn btn-primary'} onClick={getReport}>دریافت گزارش</button>
            </div>
            {loadingCat && <Loading/>}
            <table className="table table-striped">
                <thead>
                <tr>
                    <th>ردیف</th>
                    <th>دسته‌بندی</th>
                    <th>تعداد کسب و کارها</th>
                </tr>
                </thead>
                <tbody>
                {categories && categories.map((cat, index) =>
                    <tr>
                        <td>{index + 1}</td>
                        <td>{cat.name}</td>
                        <td>{loadingBiz[cat.id] ? <Loading/> : counts[cat.id]}</td>
                    </tr>
                )}
                </tbody>
            </table>
        </div>
    );
}