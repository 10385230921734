const cities = props => {
    return {
        base: {
            entities: "شهرها",
            entity: "شهر",
            module: "cities",
            path: "/cities",
            model: {
                parent_id: {title: "استان"},
                name: {title: "نام"},
                location: {title: "موقعیت"},
            },
            picker: {
                params: ["name"],
                caption: ["name"],
                fields: [{name: "name"}]
            },
        },
        list: {
            page: props.page,
            fields: [
                {name: "name"},
                {name: "parent_id"},
            ],
            search: [
                {name: "name"},
                {name: "parent_id"}
            ],
            operations: ["add", "edit", "remove"]
        },
        item: {
            id: props.id,
            fields: [
                {name: "name"},
                {name: "parent_id"},
            ],
        },
    };
};

export default cities;