const listPromotions = props => {
    return {
        base: {
            entities: "آگهی های اسپانسری",
            entity: "آگهی اسپانسری",
            module: "list_promotions",
            path: "/list_promotions",
            model: {
                image: {title: "تصویر", type: "image", width: 310, height: 250},
                service_id: {
                    title: "کسب و کار",
                    type: "picker",
                    module: "services",
                    path: "businesses",
                    caption: "title"
                },
                category_id: {
                    title: "دسته بندی",
                    type: "picker",
                    module: "categories",
                    caption: "category_name"
                },
                city_id: {
                    title: "شهر",
                    type: "picker",
                    module: "cities",
                    caption: "city_name"
                },
                title: {title: "عنوان"},
                description: {title: "توضیحات"},
                default: {title: "بنر", type: "checkbox"},
                position: {title: "موقعیت"},
                from_date: {title: "از تاریخ", type: "date"},
                to_date: {title: "تا تاریخ", type: "date"},
            }
        },
        list: {
            page: props.page,
            fields: [
                {name: "image"},
                {name: "service_id"},
                {name: "category_id"},
                {name: "city_id"},
                {name: "default"},
                {name: "position"},
                {name: "from_date"},
                {name: "to_date"},
            ],
            operations: ["add", "edit", "remove"],
            custom_button: {
                className: 'info',
                caption: 'ثبت و به روزرسانی پایگاه داده',
                click: {
                    func: props.functions.updateDB,
                    params: []
                }
            }
        },
        item: {
            id: props.id,
            fields: [
                {name: "image"},
                {name: "service_id"},
                {name: "category_id"},
                {name: "city_id"},
                {name: "default"},
                {name: "position"},
                {name: "title"},
                {name: "description"},
                {name: "from_date"},
                {name: "to_date"},
            ]
        }
    };
};

export default listPromotions;