const gifts = props => {
    return {
        base: {
            entities: "هدایا",
            entity: "هدیه",
            module: "gifts",
            path: "/gifts",
            confirm_field: "active",
            model: {
                image: {title: "آیکن", type: "simple_image"},
                title: {title: "عنوان"},
                value: {title: "ارزش"},
                data: {title: "محتوا"},
                total_count: {title: "تعداد کل"},
                used_count: {title: "تعداد استفاده شده"},
                type: {
                    title: "نوع",
                    type: "select",
                    items: [
                        {key: "single", value: "یکی"},
                        {key: "multi", value: "چندتایی"},
                        {key: "charge", value: "شارژ"}
                    ]
                },
                charge_operator: {
                    title: "اپراتور",
                    type: "select",
                    items: [
                        {key: "MTN", value: "ایرانسل"},
                        {key: "MCI", value: "همراه اول"},
                        {key: "RTL", value: "رایتل"}
                    ]
                },
                charge_amount: {title: "مبلغ شارژ"},
                description: {title: "توضیحات"},
                active: {title: "فعال", type: "checkbox"}
            }
        },
        list: {
            page: props.page,
            fields: [
                {name: "image"},
                {name: "title"},
                {name: "value", type: "formatted_number"},
                {name: "data"},
                {name: "total_count", type: "formatted_number"},
                {name: "used_count", type: "formatted_number"},
                {name: "type"},
                {name: "charge_operator"},
                {name: "charge_amount", type: "formatted_number"},
                {name: "description"},
            ],
            operations_style: {width: "170px"},
            operations: ["add", "edit", "remove", "confirm"]
        },
        item: {
            id: props.id,
            fields: [
                {name: "image"},
                {name: "title"},
                {name: "value"},
                {name: "data"},
                {name: "total_count"},
                {name: "used_count"},
                {name: "type"},
                {name: "charge_operator"},
                {name: "charge_amount"},
                {name: "description"},
                {name: "active"}
            ]
        }
    };
};

export default gifts;