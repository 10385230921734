const posts = props => {
    return {
        base: {
            entities: "پست ها",
            entity: "پست",
            module: "posts",
            path: "/posts",
            confirm_field: "confirmed",
            confirm_other_fields: ["type", "user_id", "service_title", "title"],
            model: {
                type: {
                    title: "نوع",
                    type: "select",
                    items: [
                        {key: "offers", value: "تخفیف"},
                        {key: "events", value: "رویداد"},
                        {key: "products", value: "محصول"},
                        {key: "news", value: "خبر"},
                    ]
                },
                title: {title: "عنوان"},
                photos: {title: "تصویر", type: "images", width: 600, height: 400},
                description: {title: "توضیحات"},
                action_button_title: {title: "عنوان دکمه"},
                action_button_link: {title: "لینک دکمه"},
                visit_count: {title: "بازدید"},
                min_price: {title: "حداقل قیمت", type: "formatted_number"},
                max_price: {title: "حداکثر قیمت", type: "formatted_number"},
                from_date: {title: "از تاریخ", type: "datetime"},
                to_date: {title: "تا تاریخ", type: "datetime"},
                user_id: {title: "کاربر"},
                service_id: {title: "کسب و کار"},
                service_title: {title: "کسب و کار"},
                coupon_code: {title: "کد تخفیف"},
                terms: {title: "شرایط"},
                like_count: {title: "لایک"},
                comment_count: {title: "نظر"},
                enabled: {title: "موجود", type: "checkbox"}
            }
        },
        list: {
            page: props.page,
            fields: [
                {name: "type"},
                {name: "title", max_length: 30},
                {name: "photos"},
                {
                    title: "دکمه",
                    type: "function",
                    value: {
                        func: props.functions.buttonShowLink,
                        params: ["action_button_title", "action_button_link"]
                    }
                },
                {name: "min_price"},
                {name: "max_price"},
                {name: "from_date"},
                {name: "to_date"},
                {
                    name: "service_title",
                    type: "function",
                    value: {
                        func: props.linkWithIdAndTitle,
                        params: [{static: "businesses"}, "service_id", "service_title"]
                    }
                },
                {name: "enabled"},
                {name: "visit_count"},
                {name: "like_count"},
                {name: "comment_count"},
                {name: "create_date", type: "date", alias: "date", title: "تاریخ ثبت"},
            ],
            search: [
                {
                    field_type: "between_dates",
                    type: "field",
                    name: "create_date",
                    title: "بازه تاریخی",
                },
            ],
            export_fields: [
                {name: "type"},
                {name: "title"},
                {name: "description"},
                {name: "action_button_title"},
                {name: "action_button_link"},
                {name: "min_price"},
                {name: "max_price"},
                {name: "from_date", alias: "from_date"},
                {name: "to_date", alias: "to_date"},
                {name: "service_title"},
                {name: "coupon_code"},
                {name: "terms"},
                {name: "visit_count"},
                {name: "like_count"},
                {name: "comment_count"},
                {name: "create_date", type: "date", title: "تاریخ ثبت"},
            ],
            custom_operations: [
                {
                    className: 'warning',
                    caption: {icon: 'HeartIcon'},
                    title: "لایک ها",
                    click: {
                        func: props.showListModal,
                        params: [
                            {
                                type: "static",
                                name: "title",
                                value: "لایک کنندگان"
                            },
                            {
                                type: "static",
                                name: "module",
                                value: "postLikes"
                            },
                            "id"
                        ]
                    }
                }
            ],
            operations_style: {width: "220px"},
            operations: ["add", "edit", "remove", "confirm"]
        },
        item: {
            id: props.id,
            fields: [
                {name: "type"},
                {name: "title"},
                {name: "photos"},
                {name: "description", type: "textarea", rows: 4},
                {name: "action_button_title"},
                {name: "action_button_link"},
                {name: "visit_count"},
                {name: "min_price"},
                {name: "max_price"},
                {name: "from_date"},
                {name: "to_date"},
                {name: "service_id"},
                {name: "service_title"},
                {name: "coupon_code"},
                {name: "enabled"},
                {name: "terms", type: "textarea", rows: 4},
            ]
        }
    };
};

export default posts;