const complexes = props => {
    return {
        base: {
            entities: "مجتمع های تجاری",
            entity: "مجتمع تجاری",
            module: "services",
            path: "/complexes",
            model: {
                images: {title: "تصویر", type: "images"},
                title: {title: "عنوان"},
                category_name: {title: "دسته بندی"},
                description: {title: "توضیحات"},
            }
        },
        list: {
            page: props.page,
            default_conditions: {service_type: "complex"},
            fields: [
                {name: "images"},
                {name: "title"},
                {name: "category_name"},
                {
                    title: "مدیر",
                    type: "multiple",
                    separator: " ",
                    result: [
                        {name: "user.name", type: "nested"},
                        {name: "user.family", type: "nested"}
                    ]
                },
                {name: "description", title: "توضیحات", max_length: 50},
                {
                    title: "مکان",
                    type: "multiple",
                    separator: " - ",
                    result: [
                        {name: "city_name"},
                        {name: "address", max_length: 20}
                    ]
                },
                {
                    title: "تماس",
                    type: "multiple",
                    style: {"direction": "ltr"},
                    separator: " - ",
                    result: [
                        {name: "code"},
                        {name: "phone"},
                        {name: "email"},
                        {name: "web"},
                        {
                            name: "telegram",
                            type: "social",
                            url: "https://www.t.me/",
                            icon: "telegram"
                        },
                        {
                            name: "instagram",
                            type: "social",
                            url: "https://www.instagram.com/",
                            icon: "instagram"
                        },
                    ]
                },
            ],
            operations_style: {width: '200px'},
            custom_operations: [
                {
                    className: 'info',
                    caption: 'ویرایش',
                    click: {
                        func: props.functions.handleEditComplex,
                        params: ["id"]
                    }
                },
                {
                    className: 'secondary',
                    caption: 'طبقات',
                    click: {
                        func: props.functions.handleFloors,
                        params: ['id']
                    }
                }
            ],
            operations: []
        },
    };
};

export default complexes;