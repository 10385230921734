import React, {useEffect, useState} from "react";
import {xhr} from "../components";
import {Table} from "react-bootstrap";

export default function GiftReport(){
    const [MTNUnUsed, setMTNUnUsed] = useState(0);
    const [MCIUnUsed, setMCIUnUsed] = useState(0);

    const getValues = (company, used, stateFN) => {
        new xhr({
            url: 'gift_codes',
            data: {
                fields: JSON.stringify(['id']),
                conditions: {company, used}
            }
        }).GetMany(response => {
            if(response.status){
                stateFN(response.data.count);
            }
        });

    };

    useEffect(() => {
        getValues('MTN', false, setMTNUnUsed);
        getValues('MCI', false, setMCIUnUsed);
    }, []);

    return <div>
        <h2>گزارش شارژها</h2>
        <Table striped bordered
        >
            <thead>
            <tr>
                <th>نوع</th>
                <th>تعداد مانده</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>ایرانسل</td>
                <td>{MTNUnUsed}</td>
            </tr>
            <tr>
                <td>همراه اول</td>
                <td>{MCIUnUsed}</td>
            </tr>
            </tbody>
        </Table>
    </div>;
}
