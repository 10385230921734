import React, {useContext} from "react";
import {Accordion, Card, Badge} from "react-bootstrap";
import {Context} from "../providers/mainProvider";
import {Link} from "react-router-dom";

export default function SideBar() {
    const {pages, badgeItem} = useContext(Context);

    let badge = null;
    let badgeNumber = 0;
    let activeId = null;
    let path = '';
    let {pathname} = window.location;
    let slashPos = pathname.substring(1).indexOf("/") + 1;
    if(slashPos === 0) slashPos = pathname.length;
    path = pathname.substring(0, slashPos);
    for(let p = 0; p < pages.length; p++){
        for(let o = 0; o < pages[p].options.length; o++){
            if(pages[p].options[o].path === path){
                activeId = pages[p].id;
                break;
            }
        }
        if(activeId) break;
    }

    const changePage = e => {
        if(document.getElementsByClassName('active-link')[0]){
            document.getElementsByClassName('active-link')[0].className = '';
        }
        e.target.className = 'active-link';
    };

    return (
        <div>
            <Accordion defaultActiveKey={activeId}>
                {pages.map(page => {
                    badge = null;
                    badgeNumber = 0;
                    if(page.badge){
                        for(let i = 0; i < page.badge.length; i++){
                            badgeNumber += badgeItem[page.badge[i]];
                        }
                        if(badgeNumber > 0){
                            badge = <Badge variant={"warning"}>{badgeNumber}</Badge>;
                        }
                    }
                    return <Card key={page.id}>
                            <Accordion.Toggle as={Card.Header} eventKey={page.id}>
                                {page.title} {badge ?? ''}
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey={page.id}>
                                <Card.Body>
                                    <ul className="sidebar-list">
                                        {page.options.map(option => {
                                                if (!option.hide_in_menu) {
                                                    badge = null;
                                                    if (option.badge && badgeItem[option.badge] > 0) {
                                                        badge = <Badge variant={"warning"}>{badgeItem[option.badge]}</Badge>;
                                                    }
                                                    return <li key={option.id}>
                                                        <Link to={option.path} onClick={changePage}
                                                              className={option.path === path ? "active-link" : ""}>{option.title} {badge ?? ''}</Link>
                                                    </li>;
                                                } else {
                                                    return null;
                                                }
                                            }
                                        )}
                                    </ul>
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    }
                )}
            </Accordion>
        </div>
    );
}