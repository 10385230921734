const servicesComment = props => {
    return {
        base: {
            entities: "نظرات",
            entity: "نظر",
            module: "services_comment",
            path: "/comments",
            confirm_field: "confirmed",
            confirm_other_fields: ["service_id", {name: "user.id", alias: "user_id"}, {name: "user.name", alias: "user_name"}],
            model: {
                service_id: {title: "کسب و کار"},
                user_id: {title: "کاربر"},
                text: {title: "نظر"},
                images: {title: "تصاویر", type: "images"}
            }
        },
        list: {
            page: props.page,
            fields: [
                {name: "images"},
                {name: "service_id"},
                {name: "user_id"},
                {name: "text"},
                {name: "create_date", title: "تاریخ", type: "date"},
                {name: "create_date", title: "زمان", type: "time"}
            ],
            search: [
                {
                    field_type: "picker",
                    name: "user_id",
                    module: "users",
                    search_type: "exact"
                }
            ],
            operations: ["remove", "confirm"]
        }
    };
};

export default servicesComment;