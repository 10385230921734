const reserve_billboards = props => {
    return {
        base: {
            entities: "رزروهای بیلبورد",
            entity: "رزرو بیلبورد",
            module: "reserve_billboards",
            path: "/reserve_billboards",
            model: {
                name: {title: "نام"},
                family: {title: "نام خانوادگی"},
                phone: {title: "شماره"},
            }
        },
        list: {
            page: props.page,
            fields: [
                {name: "name"},
                {name: "family"},
                {name: "phone", style: {direction: "ltr"}},
                {name: "create_date", title: "تاریخ", type: "date"},
                {name: "create_date", title: "زمان", type: "time"},
            ],
            operations: ["add", "edit", "remove"]
        },
        item: {
            id: props.id,
            fields: [
                {name: "name"},
                {name: "family"},
                {name: "phone", style: {direction: "ltr"}},
            ]
        }
    };
};

export default reserve_billboards;