const advertisements = props => {
    return {
        base: {
            entities: "تبلیغات",
            entity: "تبلیغ",
            module: "advertisements",
            path: "/advertisements",
            model: {
                module: {title: "ماژول"},
                image: {title: "تصویر", type: "image", width: 600, height: 400},
                link: {title: "لینک"},
                title: {title: "عنوان"},
                description: {title: "توضیحات"},
            }
        },
        list: {
            page: props.page,
            fields: [
                {name: "image"},
                {name: "module"},
                {name: "title"},
                {name: "link"},
                {name: "description"},
            ],
            operations: ["add", "edit", "remove"]
        },
        item: {
            id: props.id,
            fields: [
                {name: "image"},
                {name: "module"},
                {name: "title"},
                {name: "link"},
                {name: "description"},
            ]
        }
    };
};

export default advertisements;