import React from "react";
import { withScriptjs, withGoogleMap, GoogleMap, Marker, Circle } from "react-google-maps";
import MarkerClusterer from "react-google-maps/lib/components/addons/MarkerClusterer";

export default function Map(props) {
    const clicked = e => {
        console.log(e.getMarkers());
    };

    const MapComponent = withScriptjs(withGoogleMap(props =>
        <GoogleMap
            defaultZoom={props.zoom || 13}
            defaultCenter={props.center}
            onClick={props.click ? props.click : () => {
            }}
        >
            {props.isMarkerShown &&
            props.cluster ?
                <MarkerClusterer
                    onClick={clicked}
                    averageCenter
                    enableRetinaIcons
                    gridSize={60}
                >
                    {props.markers && props.markers.map(marker => {
                        // console.log(marker);
                        // TODO: fix double markers
                        return <Marker
                            key={`marker_${marker.id}`}
                            position={{
                                lat: marker.lat,
                                lng: marker.lng
                            }}
                            onClick={() => props.clickOnMarker(marker.id)}
                        />
                    })}
                </MarkerClusterer>
                :
                <Marker
                    position={props.marker}
                    onClick={() => props.clickOnMarker()}
                />
            }
            {props.circle && <Circle
                center={props.marker}
                radius={parseInt(props.circle.radius)}
                options={{
                    fillColor: "rgba(0, 0, 255, 0.5)",
                    strokeColor: "blue",
                    strokeWeight: "1"
                }}
            />}
        </GoogleMap>
    ));

    return <MapComponent
        isMarkerShown
        googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyBvPwOIiW2ygtymZM5mkaDoj-w7GgT-Ud0&v=3.exp&libraries=geometry,drawing,places"
        loadingElement={<div style={{ height: `100%` }} />}
        containerElement={<div style={{ height: props.height || `400px` }} />}
        mapElement={<div style={{ height: `100%` }} />}
        center={props.center}
        zoom={props.zoom}
        marker={props.marker}
        click={props.click}
        circle={props.circle}
        cluster={props.cluster}
        markers={props.markers}
        clickOnMarker={props.clickOnMarker}
    />;
}
