const userAchievements = props => {
    return {
        base: {
            entities: "میز کوین ها",
            entity: "میز کوین",
            module: "user_achievements",
            path: "/user_achievements",
            model: {
                user_id: {
                    title: "کاربر",
                    type: "picker",
                    module: "users",
                    path: "users",
                    caption: ""
                },
                mac_address: {title: "مک آدرس"},
                coins: {title: "میز کوین"},
                title: {title: "عنوان"},
                description: {title: "توضیحات"},
            }
        },
        list: {
            page: props.page,
            fields: [
                {name: "user_id"},
                {name: "mac_address"},
                {name: "coins", type: "formatted_number", style: {direction: 'ltr'}},
                {name: "title"},
                {name: "description"},
                {name: "create_date", title: "تاریخ", type: "date"},
                {name: "create_date", title: "زمان", type: "time"},
            ],
            search: [
                {
                    field_type: "picker",
                    name: "user_id",
                    module: "users",
                    title: "کاربر"
                },
                {
                    name: "mac_address"
                }
            ],
            operations: ['add']
        },
        item: {
            id: props.id,
            fields: [
                {name: "user_id"},
                {name: "coins"},
                {name: "title"},
                {name: "description"},
            ]
        }
    };
};

export default userAchievements;
