const giftPins = props => {
    return {
        base: {
            entities: "پین های هدیه",
            entity: "پین هدیه",
            module: "gift_pins",
            path: "/gift_pins",
            confirm_field: "active",
            model: {
                title: {title: "عنوان"},
                service_id: {
                    title: "کسب و کار",
                    type: "picker",
                    module: "services",
                    caption: ["title"]
                },
                total_count: {title: "تعداد کل"},
                used_count: {title: "تعداد استفاده شده"},
                value: {title: "ارزش"},
                location: {title: "موقعیت", type: "map", radius: "radius"},
                radius: {title: "شعاع", afterChange: true},
                pin_url: {title: "پین", type: "simple_image"},
                pin_hover_url: {title: "پین انتخاب شده", type: "simple_image"},
                from_date: {title: "شروع", type: "date"},
                to_date: {title: "پایان", type: "date"},
                catch_limit: {title: "محدودیت"},
                active: {title: "فعال", type: "checkbox"}
            }
        },
        list: {
            page: props.page,
            fields: [
                {name: "title"},
                {name: "service_id"},
                {name: "total_count"},
                {name: "used_count"},
                {name: "value"},
                {name: "pin_url"},
                {name: "pin_hover_url"},
                {name: "from_date"},
                {name: "to_date"},
                {name: "catch_limit"},
                {name: "active"}
            ],
            operations_style: {width: "170px"},
            operations: ["add", "edit", "remove", "confirm"]
        },
        item: {
            id: props.id,
            fields: [
                {name: "title"},
                {name: "service_id"},
                {name: "total_count"},
                {name: "used_count"},
                {name: "value"},
                {name: "location"},
                {name: "radius"},
                {name: "pin_url"},
                {name: "pin_hover_url"},
                {name: "from_date"},
                {name: "to_date"},
                {name: "catch_limit"},
                {name: "active"}
            ]
        }
    };
};

export default giftPins;