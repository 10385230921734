const sessions = props => {
    return {
        base: {
            entities: "نشست ها",
            entity: "نشست",
            module: "sessions",
            path: "/sessions",
            model: {
                app_version: {title: "نسخه اپ"},
                device_info: {title: "اطلاعات دستگاه"},
                imei: {title: "IMEI"},
                market: {title: "مارکت"},
                os: {title: "سیستم عامل"},
                user_id: {title: "کاربر"},
                notif_id: {title: "نوتیف"},
                token: {title: "توکن"},
                last_login: {title: "آخرین ورود", type: "datetime"},
            }
        },
        list: {
            page: props.page,
            fields: [
                {
                    name: "user_id",
                    type: "function",
                    value: {
                        func: props.linkWithIdAndTitle,
                        params: [{static: "users"}, "user_id", "user_id"]
                    }
                },
                {name: "app_version"},
                {name: "market"},
                {name: "os"},
                {name: "last_login"},
            ],
            search: [
                {
                    field_type: "text",
                    name: "user_id",
                    placeholder: "کاربر",
                    search_type: "exact",
                },
                {
                    field_type: "text",
                    name: "device_info.mac_address",
                    placeholder: "مک آدرس",
                    search_type: "exact",
                },
            ],
            custom_operations: [
                {
                    className: "info",
                    style: {marginLeft: "5px"},
                    caption: "مشخصات دستگاه",
                    click: {
                        func: props.functions.deviceInformation,
                        params: ["device_info"]
                    }
                },
            ],
            operations_style: {width: '250px'},
            operations: ["remove"]
        },
    };
};

export default sessions;
