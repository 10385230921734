import React, {useEffect, useState} from 'react';
import 'react-image-lightbox/style.css';
import {Map, xhr, Loading} from "../components";
import {Alert, Button, Modal} from "react-bootstrap";

const entities = 'نقشه کسب و کارها';
const url = 'services';
let mapLoaded = false;

export default function BusinessMap() {
    const [businesses, setBusinesses] = useState(null);
    const [message, setMessage] = useState('');
    const [show, setShow] = useState(false);
    const [businessInfo, setBusinessInfo] = useState({});

    const getRows = () => {
        new xhr({
            url,
            data: {
                fields: JSON.stringify(['location']),
                conditions: {'user.name': {$ne: 'Admin'}}
            },
            perPage: 1000000
        }).GetMany(response => {
            if(response.status) {
                let rows = response.data.list;
                let businesses = [];
                for(let i = 0; i < rows.length; i++){
                    if(rows[i].location && rows[i].location.coordinates) {
                        businesses.push({lat: rows[i].location.coordinates[1], lng: rows[i].location.coordinates[0], id: rows[i].id});
                    }
                }
                setBusinesses(businesses);
            }else{
                setMessage(<Alert bsStyle="danger">{response.note}</Alert>);
            }
        });
    };

    const handleShowBusiness = id => {
        setBusinessInfo({id: null});
        setShow(true);
        new xhr({
            url: 'services',
            data: id
        }).GetOne(business => {
            if(business){
                setBusinessInfo(business);
            }
        });
    };

    const handleHide = () => setShow(false);

    useEffect(getRows, []);

    const MyMap = props => {
        if(mapLoaded) return;
        mapLoaded = true;
        // let biz = [{lat: 32.49112424, lng: 51.43661097, id: "5aec578ea6f4527bd700625b"}];
        return(
            <div className="big-map">
                <Map
                    center={{lat: 36.300191, lng: 59.563351}}
                    markers={props.businesses}
                    // markers={biz}
                    cluster={true}
                    zoom={3}
                    clickOnMarker={handleShowBusiness}
                    height="700px"
                />
            </div>
        )
    };

    return (
        <div>
            <h2>{entities}</h2>
            {message}
            {businesses ?
                <>
                    <Modal show={show} onHide={handleHide} aria-labelledby="contained-modal-title-lg">
                        <Modal.Header closeButton>
                            <Modal.Title>اطلاعات کسب و کار</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {businessInfo.id ?
                                <div className="row">
                                    <div className="col-md-6">
                                        <a href={"/businesses/edit/" + businessInfo.id} target="_blank" rel="noopener noreferrer">{businessInfo.images[0] ? <img src={businessInfo.images[0]} className="icon" alt="Business image"/> : ""}</a><br/>
                                        <a href={"/users/edit/" + businessInfo.user.id} target="_blank" rel="noopener noreferrer">{businessInfo.user.name} {businessInfo.user.family}</a>
                                    </div>
                                    <div className="col-md-6">
                                        <a href={"/businesses/edit/" + businessInfo.id} target="_blank" rel="noopener noreferrer">{businessInfo.title}</a><br/>
                                        {businessInfo.city_name}<br/>
                                        <div className="ltr" style={{textAlign: "right"}}>{businessInfo.code + businessInfo.phone}</div>
                                        {businessInfo.description}
                                    </div>
                                </div>
                                : <Loading/>
                            }
                        </Modal.Body>
                        <Modal.Footer>
                            <Button onClick={handleHide}>بستن</Button>
                        </Modal.Footer>
                    </Modal>
                    <MyMap businesses={businesses} />
                </>
                :
                <Loading/>
            }
        </div>
    );
}
