const postCommentLikes = props => {
    return {
        base: {
            entities: "لایک نظرات",
            entity: "لایک نظر",
            module: "post_comment_likes",
            path: "/post_comment_likes",
            model: {
                user_id: {
                    title: "لایک کننده",
                    // type: "foreign",
                    // foreign: {
                    //     module: "users",
                    //     path: "/users",
                    //     field: "id",
                    //     result: [
                    //         {name: "name"},
                    //         {type: "static", value: " "},
                    //         {name: "family"}
                    //     ]
                    // }
                },
                post_comment_id: {
                    title: "نظر",
                    type: "foreign",
                    foreign: {
                        module: "post_comments",
                        path: "/post_comments",
                        field: "id",
                        result: [{name: "comment"}]
                    }
                }
            }
        },
        list: {
            page: props.page,
            default_conditions: {post_comment_id: props.id},
            no_header: true,
            fields: [
                {name: "user_id"},
            ],
            operations: []
        },
    };
};

export default postCommentLikes;