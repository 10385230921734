const giftHistories = props => {
    return {
        base: {
            entities: "تاریخچه هدایا",
            entity: "تاریخچه هدیه",
            module: "gift_histories",
            path: "/gift_histories",
            model: {
                code: {title: "کد"},
                user_id: {
                    title: "کاربر",
                    type: "foreign",
                    foreign: {
                        module: "users",
                        path: "/users",
                        field: "id",
                        result: [
                            {name: "mobile"},
                        ]
                    }
                },
                title: {title: "عنوان"},
                value: {title: "مقدار"},
                gift_id: {title: "هدیه"},
            }
        },
        list: {
            page: props.page,
            fields: [
                {name: "user_id"},
                {
                    name: "title",
                    type: "function",
                    value: {
                        func: props.linkWithIdAndTitle,
                        params: [{static: "gifts"}, "gift_id", "title"]
                    }
                },
                {name: "code"},
                {name: "value", style: {direction: "ltr", textAlign: "right"}},
                {name: "create_date", type: "date", alias: "date", title: "تاریخ"},
                {name: "create_date", type: "time", alias: "time", title: "زمان"},
            ],
            search: [
                {
                    field_type: "picker",
                    name: "user_id",
                    module: "users",
                    search_type: "exact"
                },
                {
                    field_type: "between_dates",
                    type: "field",
                    name: "create_date",
                    title: "بازه تاریخی",
                },
            ],
            export_fields: [
                {name: "user_id"},
                {name: "title"},
                {name: "code"},
                {name: "value"},
                {name: "create_date", type: "date", alias: "date", title: "تاریخ"},
                {name: "create_date", type: "time", alias: "time", title: "زمان"},
            ],
            operations: []
        },
    };
};

export default giftHistories;