const recommendations = props => {
    return {
        base: {
            entities: "پیشنهادات",
            entity: "پیشنهاد",
            module: "recommendations",
            path: "/recommendations",
            model: {
                module: {title: "ماژول"},
                image: {title: "تصویر", type: "image", width: 45, height: 45},
                query: {title: "کوئری", type: "json"},
                sort: {title: "ترتیب", type: "json"},
                title: {title: "عنوان"},
            }
        },
        list: {
            page: props.page,
            fields: [
                {name: "image"},
                {name: "title"},
                {
                    name: "query",
                    style: {direction: "ltr", textAlign: "right"},
                },
                {
                    name: "sort",
                    style: {direction: "ltr", textAlign: "right"},
                },
            ],
            operations: ["add", "edit", "remove"]
        },
        item: {
            id: props.id,
            fields: [
                {name: "image"},
                {name: "title"},
                {name: "query"},
                {name: "sort"},
            ]
        }
    };
};

export default recommendations;