const importCharges = () => {
    return {
        base: {
            entities: "شارژها",
            entity: "شارژ",
            module: "import_charges",
            path: "/import_charges",
            model: {
                text: {title: "شارژها"},
                format: {title: "فرمت"}
            }
        },
        item: {
            fields: [
                {name: "format", default: "4030"},
                {name: "text", type: "textarea", rows: 25}
            ]
        }
    };
};

export default importCharges;