const news = props => {
    return {
        base: {
            entities: "اخبار",
            entity: "خبر",
            module: "news",
            path: "/news",
            server: "news",
            locale: "en",
            model: {
                source_id: {title: "منبع"},
                // source_id: {
                //     title: "منبع",
                //     type: "foreign",
                //     foreign: {
                //         module: "sources",
                //         path: "/sources",
                //         field: "id",
                //         result: [{name: "name"}]
                //     }
                // },
                url: {title: "لینک"},
                url_hash: {title: "هش لینک"},
                title: {title: "عنوان"},
                summary: {title: "خلاصه"},
                date: {title: "تاریخ"},
                source_url: {title: "لینک منبع"},
                status: {
                    title: "وضعیت",
                    type: "select",
                    items: [
                        {key: "summary", value: "خلاصه"},
                        {key: "text", value: "متن کامل"}
                    ]
                },
                image: {title: "تصویر", type: "image"},
                text_selector: {title: "سلکتور"},
                html: {title: "متن خبر"},
            }
        },
        list: {
            page: props.page,
            fields: [
                {name: "source_id"},
                {name: "image"},
                {
                    name: "title",
                    type: "function",
                    value: {
                        func: props.linkWithUrlAndTitle,
                        params: ["url", "title"]
                    }
                },
                {
                    name: "html",
                    type: "function",
                    value: {
                        func: props.functions.newsButton,
                        params: ["html"]
                    }
                },
                {name: "date"},
                {name: "status"},
                {name: "create_date", title: "تاریخ دریافت", type: "date", alias: "cdate"},
                {name: "create_date", title: "زمان دریافت", type: "time", alias: "ctime"}
            ],
            search: [
                // {
                //     field_type: "select",
                //     name: "source_id",
                //     source_data: props.sources,
                //     placeholder: "منبع",
                //     search_type: "exact",
                // },
                {
                    field_type: "text",
                    name: "title",
                    placeholder: "عنوان",
                },
                {
                    field_type: "text",
                    name: "summary",
                    placeholder: "خلاصه",
                },
                {
                    field_type: "text",
                    name: "text",
                    placeholder: "متن",
                },
                {
                    field_type: "select",
                    name: "status",
                    placeholder: "وضعیت",
                    search_type: "exact",
                    source_data: [
                        {key: "summary", value: "خلاصه"},
                        {key: "text", value: "متن کامل"}
                    ]
                },
            ],
            operations: []
        },
        item: {
            id: props.id,
            fields: [
                {name: "image"},
                {name: "title"},
                {name: "url"},
                {name: "url_hash"},
                {name: "summary"},
                {name: "date"},
                {name: "status"},
                {name: "source_id"},
                {name: "source_url"},
                {name: "text_selector"},
                {name: "text"},
                {name: "html"},
            ]
        }
    };
};

export default news;