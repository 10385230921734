const crm_subjects = props => {
    return {
        base: {
            entities: "موضوعات",
            entity: "موضوع",
            module: "crm_subjects",
            path: "/crm_subjects",
            model: {
                title: {title: "عنوان"},
            }
        },
        list: {
            page: props.page,
            fields: [
                {name: "title"},
            ],
            operations: ["add", "edit", "remove"]
        },
        item: {
            id: props.id,
            fields: [
                {name: "title"},
            ]
        }
    };
};

export default crm_subjects;