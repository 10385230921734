import React, {useContext} from "react";
import {Navbar, Nav, NavDropdown} from "react-bootstrap";
import {Context} from "../providers/mainProvider";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";

const languages = [
    {code: 'fa', title: 'فارسی'},
    {code: 'en', title: 'English'},
    {code: 'ar', title: 'عربی'}
];

export default function Header(props) {
    const {auth, logout, locale, setLocale} = useContext(Context);
    const {open, setOpen} = props.props;

    return (
        <header className="main-header">
            <Navbar bg="light" expand="lg">
                <Navbar.Brand>
                    <Link onClick={() => setOpen(!open)} to="#"><div className="toggle-menu"><FontAwesomeIcon icon={faBars}/></div></Link>
                    <Link to="/">میز</Link>
                </Navbar.Brand>
                <Nav className="mr-auto"/>
                <NavDropdown title={"زبان : " + languages.filter(item => item.code === locale)[0].title} id="basic-nav-dropdown">
                    {languages.map(lang =>
                        <NavDropdown.Item><Link to={"#"} onClick={() => setLocale(lang.code)}>{lang.title}</Link></NavDropdown.Item>
                    )}
                </NavDropdown>
                {auth ?
                    <Nav.Item><Link onClick={() => logout()} to="#">خروج</Link></Nav.Item>
                    :
                    <Nav.Item><Link to="/login">ورود</Link></Nav.Item>
                }
            </Navbar>
        </header>
    );
}