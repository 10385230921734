module.exports = {
    ROLES: [
        {key: "guest", value: "مهمان"},
        {key: "user", value: "کاربر"},
        {key: "complex", value: "مجتمع تجاری"},
        {key: "reporter", value: "گزارشگیر"},
        {key: "consultant", value: "مشاور"},
        {key: "tester", value: "تستر"},
        {key: "operator", value: "اپراتور"},
        {key: "admin", value: "مدیر"}
    ],
    TEST_URL: [
        "localhost",
        "testadmin.onmiz.net"
    ],
    SERVERS_DROPDOWN: [
        {key: "https://mizapi.testpad.ir/", value: "سرور تست"},
        {key: "https://server1.onmiz.org/", value: "سرور پروداکشن"},
        {key: "http://192.168.2.3:8282/", value: "سرور شاتل"},
        {key: "http://192.168.1.200:8282/", value: "سرور ایرانسل"},
    ],
    SERVERS: {
        base: "https://server1.onmiz.org/",
        log: "https://logapi.onmiz.info/v1/",
        backup: "https://server1.onmiz.org:8090/",
        news: "https://newsapi.onmiz.info/v1/",
        chat: "https://server1.onmiz.org/"
    },
};
