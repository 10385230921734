export default class LocalStorage {
    setItem = props => {
        localStorage.setItem(props.name, props.value);
    };

    setItemsBatch = props => {
        for(let i = 0; i < props.length; i++){
            localStorage.setItem(props[i].name, props[i].value);
        }
    };

    getItem = name => {
        return localStorage.getItem(name);
    };

    removeItem = name => {
        localStorage.removeItem(name);
    };

    removeItemsBatch = props => {
        for(let i = 0; i < props.length; i++){
            localStorage.removeItem(props[i].name);
        }
    };
}