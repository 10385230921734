const events = props => {
    return {
        base: {
            entities: "رویدادها",
            entity: "رویداد",
            module: "events",
            path: "/events",
            model: {
                user_id: {title: "کاربر"},
                name: {
                    title: "عملیات",
                    type: "select",
                    items: [
                        {key: "pin_click", value: "کلیک روی پین"},
                        {key: "share_service", value: "اشتراک کسب و کار"},
                        {key: "direction", value: "مسیریابی"},
                        {key: "invitation", value: "دعوت"},
                        {key: "call", value: "تماس"},
                        {key: "billboard", value: "کلیک روی بیلبورد"},
                    ]
                },
                data: {title: "اطلاعات"},
            }
        },
        list: {
            page: props.page,
            fields: [
                {name: "user_id"},
                {name: "name"},
                {
                    name: "data",
                    type: "json",
                    style: {direction: "ltr", textAlign: "left"},
                },
                {name: "create_date", title: "تاریخ", type: "date"},
                {name: "create_date", title: "زمان", type: "time"},
            ],
            operations: []
        },
    };
};

export default events;