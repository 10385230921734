import React, {useEffect, useState} from "react";
import {Cookies, Loading, xhr} from "../components";
import {Container, Row, Col, Form, Button, Badge} from "react-bootstrap";
// import { io } from 'socket.io-client';
//
// const socket = io('http://192.168.1.200:5000');

export default function Support () {
    const [conversations, setConversations] = useState([]);
    const [newMessage, setNewMessage] = useState('');
    const [messages, setMessages] = useState([]);
    const [selectedConversation, setSelectedConversation] = useState(0);
    const [sending, setSending] = useState(false);
    const [page, setPage] = useState(1);
    const [pages, setPages] = useState(1);
    const [currentUserId, setCurrentUserId] = useState(null);

    const cookie = new Cookies();

    const loadConversations = (loadPage = page) => {
        new xhr({
            url: 'my_conversations',
            data: {
                sort: JSON.stringify({'_id': -1})
            },
            page: loadPage,
            server: 'chat'
        }).GetMany(response => {
            if(response.status){
                let count = response.data.count;
                setPages(Math.ceil(count / 25));
                setConversations(response.data.list);
            }else{
                setConversations([]);
            }
        });
    };

    const changeMsg = e => {
        let target = e.target;
        setNewMessage(target.value);
    };

    const loadMessages = (id, current_user_id) => {
        setSelectedConversation(id);
        setMessages([]);
        setCurrentUserId(current_user_id);
        let data = {
            conditions: {conversation_id: id}
        };
        new xhr({
            url: 'chat',
            data,
            perPage: 100,
            server: 'chat'
        }).GetMany(response => {
            if(response.status){
                setMessages(response.data.list);
                setConversations(conversations.map(conv => {
                    if(conv.id === id){
                        conv.unread = 0;
                    }
                    return conv;
                }));
            }else{
                setMessages([]);
            }
        });
    };

    const sendMessage = () => {
        if(!selectedConversation) return;
        setSending(true);
        new xhr({
            url: 'chat',
            data: {
                message : newMessage,
                user_id  : currentUserId,
                user_name  : 'پشتیبانی میز',
                conversation_id: selectedConversation || ''
            },
            server: 'chat'
        }).Post(response => {
            if(response.status){
                const cookie = new Cookies();
                let msg = [...messages];
                msg.push({
                    message: newMessage,
                    from: cookie.getCookie("UID")
                });
                setMessages(msg);
                setNewMessage('')
            }
            setSending(false);
        });
    };

    const prevConv = () => {
        if(page === 1) return;
        let p = page;
        setPage(p - 1);
        loadConversations(p - 1);
    };

    const nextConv = () => {
        if(page === pages) return;
        let p = page;
        console.log(p);
        setPage(p + 1);
        loadConversations(p + 1);
    };

    useEffect(() => {
        // socket.on('connect', () => {
        //     console.log('socket id:', socket.id);
        // });
        // socket.emit('hello', 'world');
        loadConversations();
    }, []);

    return (
        <Container fluid>
            <Row>
                {}
            </Row>
            <Row>
                <Col md={3} xs={2} className="conversations">
                    {conversations ?
                        <>
                            <ul>
                                {conversations.map(conv =>
                                    <li
                                        className={conv.id === selectedConversation ? "active" : ""}
                                        onClick={() => loadMessages(conv.id, conv.user_info.id)}
                                    >
                                        {conv.user_info.name}
                                        {conv.unread > 0 && <div className="badge-container"><Badge variant="info">{conv.unread}</Badge></div>}
                                    </li>
                                )}
                            </ul>
                            <div className="page-container">
                                <Button variant="info" size="sm" onClick={prevConv} disabled={parseInt(page) === 1}>{"< قبلی"}</Button>
                                {' '}
                                <Button variant="info" size="sm" onClick={nextConv} disabled={parseInt(page) === parseInt(pages)}>{"بعدی >"}</Button>
                            </div>
                        </>
                        :
                        <Loading/>
                    }
                </Col>
                <Col md={9} xs={10} className="fill-height">
                    <div className="chat-box">
                        <div className="messages">
                            {selectedConversation ?
                                messages ?
                                    messages.map(msg =>
                                        <span>
                                            <div className={msg.from !== cookie.getCookie('UID') ? "message other" : "message me"}>{msg.message}</div>
                                            <div className="clear-fix"/>
                                        </span>
                                    )
                                    :
                                    <Loading/>
                                :
                                <div className="message-full">یکی از مخاطبین را انتخاب کنید</div>
                            }
                        </div>
                        <div className="new-message">
                            <Form.Control
                                as="textarea"
                                className="new-message-textarea"
                                name="message"
                                value={newMessage}
                                rows={4}
                                onChange={changeMsg}
                                disabled={sending}
                            />
                            <Button bsStyle="primary" block onClick={sendMessage} disabled={!selectedConversation || sending}>ارسال</Button>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    );
}
