import React, { Component } from 'react';
import {Button, Alert} from 'react-bootstrap';
import xhr from '../components/xhr';

export default class Backup extends Component {
    state = {
        loading: false,
        message: ''
    };

    download = () => {
        this.setState({loading: true});
        new xhr({url: "download_db"}).GetOne(result => {
            this.setState({loading: false});
            if(!result.status){
                this.setState({message: <Alert variant="danger">{result.note}</Alert>});
            }
        });
    };

    render(){
        let {loading, message} = this.state;
        return (
            <div>
                <h2>بکاپ از دیتابیس</h2>
                {message}
                <Button bsStyle="primary" disabled={loading} onClick={() => this.download()}>{loading ? "لطفاً صبر کنید..." : "دانلود بکاپ دیتابیس"}</Button>
            </div>
        );
    }
}