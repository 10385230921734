import React, {useEffect, useState} from "react";
import {Loading, xhr} from "../components";
import ReactChartkick, { ColumnChart } from 'react-chartkick';
import Chart from 'chart.js';
import moment from 'moment-jalaali';
import {
    Accordion,
    Card,
    Container,
    Row,
    Col,
    Table,
    ButtonGroup,
    Button,
    Form
} from "react-bootstrap";

export default function Dashboard() {
    const [items, setItems] = useState({});
    const [logItems, setLogItems] = useState({});
    const [chartData, setChartData] = useState(null);
    const [period, setPeriod] = useState('daily');
    const [number, setNumber] = useState(30);
    const [chartName, setChartName] = useState('');
    const [module, setModule] = useState('');
    const [loadingChart, setLoadingChart] = useState(false);

    const showStat = () => {
        new xhr({url: 'statistics'}).GetMany(response => {
            if(response.status){
                setItems(response.data.item);
            }
        });
    };

    const generateChart = (mdl = module, prd = period) => {
        new xhr({
            url: 'statistics',
            data: {
                chart: mdl,
                count: number,
                type: prd
            }
        }).GetMany(response => {
            if(response.status){
                let list = response.data.list;
                if(list) {
                    let first_chart_data = {};
                    let dates = [];
                    for (let i = 0; i < list.length; i++) {
                        switch(prd){
                            case "daily":
                                list[i].date = list[i].date ? moment(list[i].date, "YYYY-MM-DD").format("jYYYY/jMM/jDD") : '';
                                break;
                            case "weekly":
                                list[i].date = list[i].date.year + " هفته " + list[i].date.week;
                                break;
                            case "monthly":
                                if(list[i].date.month < 10){
                                    list[i].date.month = "0" + list[i].date.month;
                                }
                                list[i].date = list[i].date.year + "/" + list[i].date.month;
                                break;
                            default:
                                break;
                        }
                        dates.push(list[i].date);
                        first_chart_data[list[i].date] = list[i].count;
                    }
                    dates = dates.sort();
                    let chart_data = {};
                    for(let i = 0; i < dates.length; i++){
                        chart_data[dates[i]] = first_chart_data[dates[i]];
                    }
                    setChartData(chart_data);
                }
            }
            setLoadingChart(false);
        });
    };

    const changeChart = (e, mdl) => {
        e.preventDefault();
        if(mdl){
            switch(mdl){
                case "users":
                    setChartName("کاربران");
                    break;
                case "services":
                    setChartName("کسب و کارها");
                    break;
                default:
                    return;
            }
            setModule(mdl);
            setLoadingChart(true);
            generateChart(mdl);
        }
    };

    const changePeriod = prd => {
        setPeriod(prd);
        generateChart(module, prd);
    };

    const changeNumber = e => {
        e.preventDefault();
        generateChart();
    };

    const formatNum = num => {
        if(!num) return '-';
        return new Intl.NumberFormat().format(num);
    };

    return (
        <div>
            <h2>داشبورد مدیریتی میز</h2>
            <p>&nbsp;</p>
            <Button variant={"primary"} onClick={showStat}>نمایش آمارها</Button>
            <p>&nbsp;</p>
            <Container fluid>
                <Row>
                    <Col>
                        <Accordion>
                            <Card>
                                <Card.Header>
                                    کاربران
                                </Card.Header>
                                <Card.Body>
                                    <Table striped bordered>
                                        <tbody>
                                        <tr>
                                            <td><a href="/" className="link" onClick={e => changeChart(e, "users")}>تعداد کل</a></td>
                                            <td><a href="/" className="link" onClick={e => changeChart(e, "users")}>{formatNum(items.users)}</a></td>
                                        </tr>
                                        <tr>
                                            <td>تعداد اشتراک گذاری کسب و کارها</td>
                                            <td>{formatNum(items.sharing_services)}</td>
                                        </tr>
                                        <tr>
                                            <td>تعداد دعوت از دوستان</td>
                                            <td>{formatNum(items.invite_friends)}</td>
                                        </tr>
                                        <tr>
                                            <td>تعداد تکمیل پروفایل کاربران</td>
                                            <td>{formatNum(logItems.profile_completion)}</td>
                                        </tr>
                                        </tbody>
                                    </Table>
                                </Card.Body>
                            </Card>
                        </Accordion>
                    </Col>
                    <Col>
                        <Accordion>
                            <Card>
                                <Card.Header>
                                    کسب و کارها
                                </Card.Header>
                                <Card.Body>
                                    <Table striped bordered>
                                        <tbody>
                                        <tr>
                                            <td><a href="/" className="link" onClick={e => changeChart(e, "services")}>تعداد کل</a></td>
                                            <td><a href="/" className="link" onClick={e => changeChart(e, "services")}>{formatNum(items.services)}</a></td>
                                        </tr>
                                        <tr>
                                            <td>تعداد مشاهده کادر کسب و کار در صفحه نقشه</td>
                                            <td>{formatNum(items.show_pins)}</td>
                                        </tr>
                                        <tr>
                                            <td>تعداد مشاهده صفحه کسب و کارها</td>
                                            <td>{formatNum(logItems.show_services)}</td>
                                        </tr>
                                        <tr>
                                            <td>تعداد تماس با کسب و کارها</td>
                                            <td>{formatNum(items.call_services)}</td>
                                        </tr>
                                        </tbody>
                                    </Table>
                                </Card.Body>
                            </Card>
                        </Accordion>
                    </Col>
                    <Col>
                        <Accordion>
                            <Card>
                                <Card.Header>
                                    جستجوها
                                </Card.Header>
                                <Card.Body>
                                    <Table striped bordered>
                                        <tbody>
                                        <tr>
                                            <td>تعداد کل</td>
                                            <td>{logItems.phrase_search && logItems.category_search && logItems.phrase_search > 0 ? formatNum(logItems.phrase_search + logItems.category_search) : formatNum(logItems.category_search)}</td>
                                        </tr>
                                        <tr>
                                            <td>تعداد جستجوی متنی</td>
                                            <td>{formatNum(logItems.phrase_search)}</td>
                                        </tr>
                                        <tr>
                                            <td>تعداد جستجو در دسته بندی ها</td>
                                            <td>{formatNum(logItems.category_search)}</td>
                                        </tr>
                                        <tr>
                                            <td>تعداد مسیریابی ها</td>
                                            <td>{formatNum(items.routing)}</td>
                                        </tr>
                                        </tbody>
                                    </Table>
                                </Card.Body>
                            </Card>
                        </Accordion>
                    </Col>
                </Row>
                <Row><Col>&nbsp;</Col></Row>
                {(chartData || loadingChart) && <Row>
                    <Col>
                        <Accordion>
                            <Card>
                                <Card.Header>
                                    نمودار {chartName}
                                </Card.Header>
                                <Card.Body>
                                    {loadingChart ?
                                        <Row>
                                            <Col>
                                                <Loading/>
                                            </Col>
                                        </Row>
                                        :
                                        <>
                                            <Row>
                                                <Col>
                                                    <ButtonGroup>
                                                        <Button onClick={() => changePeriod('monthly')} variant={period === 'monthly' ? 'primary' : 'light'}>ماهانه</Button>
                                                        <Button onClick={() => changePeriod('weekly')} variant={period === 'weekly' ? 'primary' : 'light'}>هفتگی</Button>
                                                        <Button onClick={() => changePeriod('daily')} variant={period === 'daily' ? 'primary' : 'light'}>روزانه</Button>
                                                    </ButtonGroup>
                                                </Col>
                                                <Col>
                                                    <Form onSubmit={changeNumber}>
                                                        <Form.Row>
                                                            <Col xs="auto">
                                                                <Form.Control
                                                                    type="number"
                                                                    name="number"
                                                                    value={number}
                                                                    onChange={e => setNumber(e.target.value)}
                                                                />
                                                            </Col>
                                                            <Col xs="auto">
                                                                <Button type="submit">اعمال</Button>
                                                            </Col>
                                                        </Form.Row>
                                                    </Form>
                                                </Col>
                                            </Row>
                                            <ColumnChart data={chartData} />
                                        </>
                                    }
                                </Card.Body>
                            </Card>
                        </Accordion>
                    </Col>
                </Row>}
            </Container>
        </div>
    );
}