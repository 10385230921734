const logs = props => {
    return {
        base: {
            entities: "لاگ ها",
            entity: "لاگ",
            module: "logs",
            path: "/logs",
            model: {
                user_id: {
                    title: "کاربر",
                    // type: "foreign",
                    // foreign: {
                    //     module: "users",
                    //     path: "/users",
                    //     field: "id",
                    //     result: [
                    //         {name: "name"},
                    //         {type: "static", value: " "},
                    //         {name: "family"}
                    //     ]
                    // }
                },
                ip: {title: "آی پی"},
                method: {title: "متد"},
                module: {title: "ماژول"},
                url: {title: "آدرس"},
                http_code: {title: "کد"},
                duration: {title: "مدت"},
            }
        },
        list: {
            page: props.page,
            get_fields: ["user_id", "ip", "method", "url", "http_code", "status", "date", "duration", "module"],
            fields: [
                {
                    name: "user_id",
                    type: "function",
                    value: {
                        func: props.linkWithIdAndTitle,
                        params: [{static: "users"}, "user_id", "user_id"]
                    }
                },
                {name: "ip"},
                {name: "method"},
                {name: "module"},
                {name: "http_code"},
                {name: "date", title: "تاریخ", type: "date", alias: "log_date"},
                {name: "date", title: "زمان", type: "time", alias: "log_time"},
                {
                    name: "duration",
                    type: "function",
                    value: {
                        func: props.roundToDeci,
                        params: ["duration"]
                    }
                }
            ],
            search: [
                {
                    field_type: "text",
                    name: "user_id",
                    placeholder: "کاربر",
                    search_type: "exact",
                },
                {
                    field_type: "text",
                    name: "ip",
                    placeholder: "IP",
                    search_type: "exact",
                },
                {
                    field_type: "text",
                    name: "module",
                    placeholder: "ماژول",
                    search_type: "exact",
                },
                {
                    field_type: "radio",
                    name: "method",
                    placeholder: "متد",
                    search_type: "exact",
                    source_data: [
                        {key: 'GET', value: 'get'},
                        {key: 'POST', value: 'post'},
                        {key: 'PUT', value: 'put'},
                        {key: 'DELETE', value: 'delete'},
                    ]
                },
                {
                    field_type: "between",
                    name: "http_code",
                    placeholder: "کد",
                },
                {
                    field_type: "between_dates",
                    name: "date",
                    placeholder: "بازه تاریخی",
                },
            ],
            custom_operations: [
                // {
                //     className: "info",
                //     style: {marginLeft: "5px"},
                //     caption: "جزئیات بدون خروجی",
                //     click: {
                //         func: props.functions.showLogInfoWithoutOutput,
                //         params: ["id"]
                //     }
                // },
                {
                    className: "info",
                    style: {marginLeft: "5px"},
                    caption: "جزئیات",
                    click: {
                        func: props.functions.showLogInfoAll,
                        params: ["id"]
                    }
                },
            ],
            // operations_style: {width: '270px'},
            operations: [],
        },
    };
};

export default logs;