const server_messages = props => {
    return {
        base: {
            entities: "پیام‌های سیستمی",
            entity: "پیام سیستمی",
            module: "server_messages",
            path: "/server_messages",
            model: {
                group: {title: "گروه"},
                name: {title: "نام"},
                code: {title: "کد"},
                status: {
                    title: "وضعیت",
                    type: "radio",
                    value_type: "boolean",
                    items: [
                        {key: true, value: "موفق"},
                        {key: false, value: "ناموفق"},
                    ]
                },
                fa: {title: "فارسی"},
                ar: {title: "عربی"},
                en: {title: "انگلیسی"},
            }
        },
        list: {
            page: props.page,
            fields: [
                {name: "group"},
                {name: "name"},
                {name: "code"},
                {name: "status"},
                {name: "fa"},
                {name: "ar"},
                {name: "en", style: {direction: "ltr", textAlign: "left"}}
            ],
            search: [
                {name: "group"},
                {name: "name"},
                {name: "code", search_type: "exact", type: "number"},
                {name: "fa"},
                {name: "ar"},
                {name: "en"}
            ],
            operations: ["add", "edit", "remove"]
        },
        item: {
            id: props.id,
            fields: [
                {name: "group"},
                {name: "name"},
                {name: "code"},
                {name: "status"},
                {name: "fa"},
                {name: "ar"},
                {name: "en", style: {direction: "ltr", textAlign: "left"}}
            ]
        }
    };
};

export default server_messages;