const giftDetails = props => {
    return {
        base: {
            entities: "مقادیر هدایا",
            entity: "مقدار هدیه",
            module: "gift_details",
            path: "/gift_details",
            model: {
                gift_id: {title: "هدیه"},
                data: {title: "مقدار"},
                status: {
                    title: "وضعیت",
                    type: "radio",
                    items: [
                        {key: "used", value: "استفاده شده"},
                        {key: "unused", value: "استفاده نشده"}
                    ]
                },
            }
        },
        list: {
            page: props.page,
            fields: [
                {name: "gift_id"},
                {name: "data"},
                {name: "status"},
            ],
            operations: ["add", "edit", "remove"]
        },
        item: {
            id: props.id,
            fields: [
                {name: "gift_id"},
                {name: "data"},
                {name: "status"},
            ]
        }
    };
};

export default giftDetails;