import React, { Component } from 'react';
import {Button, Alert, Form, Accordion, Modal, Card} from "react-bootstrap";
import xhr from "../components/xhr";
import SelectSearch from "react-select-search";
import moment from 'moment-jalaali';
import DatePicker from 'react-datepicker2';
import {StyledImage} from "../components/image";

const entity = "آگهی اسپانسری";
const url = 'sponsor_packages_orders';
const loadingGif = <span className="glyphicon glyphicon-repeat fast-right-spinner"/>;

export default class SponsorPackagesOrder extends Component {
    constructor(props){
        super(props);
        if(props.match.params.id){
            this.title = "ویرایش " + entity;
            this.id = props.match.params.id;
        }else{
            this.title = "افزودن " + entity + " جدید";
            this.id = null;
        }
        this.state = {
            item: {},
            message: null,
            submitLoading: false,
            categories: [{name: '', value: ''}],
            cities: [{name: '', value: ''}],
            packages: [],
            period_message: '',
            show_add_period: false,
            period: {from_date: '', to_date: ''}
        };
        this.imageSelectRef = React.createRef();
    }

    componentWillMount(){
        if(this.id) {
            new xhr({
                parent: this,
                url,
                data: this.id
            }).GetOne((item) => {
                this.setState({item});
            });
        }

        new xhr({
            parent: this,
            url: 'cities',
            data: {conditions: {parent_id: {$ne: ''}}},
            page: -1
        }).GetMany(response => {
            if(response.status) {
                let list = response.data.data.list;
                let cities = [];
                for(let i = 0; i < list.length; i++){
                    cities.push({name: list[i].name, value: list[i].id});
                }
                this.setState({cities});
            }
        });

        new xhr({
            parent: this,
            url: 'categories',
            data: {conditions: {parent_id: {$ne: ''}}},
            page: -1
        }).GetMany(response => {
            if(response.status){
                let list = response.data.data.list;
                let categories = [];
                for(let i = 0; i < list.length; i++){
                    categories.push({name: list[i].name, value: list[i].id});
                }
                this.setState({categories});
            }
        });

        new xhr({
            parent: this,
            url: 'sponsor_packages',
            page: -1
        }).GetMany(response => {
            if(response.status){
                let list = response.data.data.list;
                let packages = list.map(item => <option value={item.id}>{item.title}</option>);
                this.setState({packages});
            }
        });
    }

    handleSubmit = (event) => {
        event.preventDefault();
        this.setState({
            submitLoading: true
        });
        let data = this.state.item;
        let temp_date;
        for(let i = 0; i < data.periods.length; i++){
            temp_date = data.periods[i].from_date.split(' ');
            data.periods[i].from_date = temp_date[0];
            temp_date = data.periods[i].to_date.split(' ');
            data.periods[i].to_date = temp_date[0];
        }
        if(this.id){
            data.id = this.id;
            new xhr({
                parent: this,
                url,
                data
            }).Put((response) => {
                if(response.status){
                    this.setState({
                        message: <Alert variant="success">با موفقیت ثبت شد</Alert>,
                        submitLoading: false,
                    });
                }else{
                    this.setState({
                        message: <Alert variant="danger">{response.note}</Alert>,
                        submitLoading: false,
                    });
                }
            });
        }else{
            new xhr({
                parent: this,
                url,
                data
            }).Post((response) => {
                if(response.status){
                    this.setState({
                        message: <Alert variant="success">با موفقیت ثبت شد</Alert>,
                        submitLoading: false,
                    });
                }else{
                    this.setState({
                        message: <Alert variant="danger">{response.note}</Alert>,
                        submitLoading: false,
                    });
                }
            });
        }
    };

    handleChange = (event) => {
        let target = event.target;
        let item = this.state.item;
        item[target.name] = target.value;
        this.setState({item});
    };

    handleChangeCategory = (value) => {
        if(value){
            let item = this.state.item;
            item.category_name = value.name;
            item.category_id = value.value;
            this.setState(item);
        }
    };

    handleChangeCity = (value) => {
        if(value){
            let item = this.state.item;
            item.city_name = value.name;
            item.city_id = value.value;
            this.setState(item);
        }
    };

    removePeriod = (e) => {
        let target = e.target;
        let item = this.state.item;
        item.periods = item.periods.filter(period => period.from_date !== target.id);
        this.setState({item});
    };

    handleHide = () => {
        this.setState({show_add_period: false, period: {from_date: '', to_date: ''}});
    };

    changeFromDate = value => {
        let period = this.state.period;
        period.from_date = value;
        this.setState({period});
    };

    changeToDate = value => {
        let period = this.state.period;
        period.to_date = value;
        this.setState({period});
    };

    handleAddPeriod = () => {
        let period = this.state.period;
        if(period.from_date && period.to_date){
            let item = this.state.item;
            item.periods.push(period);
            this.setState({item}, () => {
                this.setState({period: {from_date: '', to_date: ''}, show_add_period: false});
            });
        }
    };

    handleUpload = () => {
        let file = this.imageSelectRef.current.handleFinalImage();
        let data = new FormData();
        data.append('image', file);
        data.append('type', url);
        new xhr({
            parent: this,
            url: 'upload',
            data
        }).Upload(link => {
            if(link){
                let item = this.state.item;
                item.image = link;
                this.setState({item});
            }
        });
    };

    handleRemoveImage(image) {
        let item = this.state.item;
        item.image = '';
        this.setState({item});
    }

    handleSelect(e, type){
        this.imageType = type;
        this.imageSelectRef.current.onSelectFile(e);
    }

    render(){
        const {item, message, submitLoading, cities, packages, categories, period_message, show_add_period, period} = this.state;
        return (
            <div>
                <h2>{this.title}</h2>
                <form onSubmit={this.handleSubmit}>
                    {message}
                    <Form.Group>
                        <Form.Label>تصویر بنر</Form.Label>
                        <StyledImage
                            width={400}
                            height={300}
                            upload={this.handleUpload}
                        />
                        {item.image &&
                        <div>
                            <img src={item.image} className="icon"/>
                            <Button variant="danger" title="حذف" onClick={() => this.handleRemoveImage()}>×</Button>
                        </div>
                        }
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>نوع آگهی اسپانسری</Form.Label>
                        <Form.Control
                            as="select"
                            name="sponsor_package_id"
                            value={item.sponsor_package_id}
                            onChange={this.handleChange}
                        >
                            {packages.map(pkg => pkg)}
                        </Form.Control>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>دسته بندی</Form.Label>
                        {categories.length > 1 &&
                        <SelectSearch
                            options={categories}
                            value={item.category_id}
                            name="category_id"
                            placeholder="دسته بندی را انتخاب کنید"
                            onChange={this.handleChangeCategory.bind(this)}
                        />
                        }
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>شهر</Form.Label>
                        {cities.length > 1 &&
                        <SelectSearch
                            options={cities}
                            value={item.city_id}
                            name="city_id"
                            placeholder="شهر را انتخاب کنید"
                            onChange={this.handleChangeCity}
                        />
                        }
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>قیمت</Form.Label>
                        <Form.Control
                            name="price"
                            type="text"
                            value={item.price}
                            onChange={this.handleChange}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>نوع</Form.Label>
                        <Form.Control
                            as="select"
                            name="type"
                            value={item.type}
                            onChange={this.handleChange}
                        >
                            <option value=''>نوع را انتخاب کنید</option>
                            <option value="list">لیست</option>
                            <option value="banner">بنر</option>
                        </Form.Control>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>وضعیت</Form.Label>
                        <Form.Control
                            as="select"
                            name="confirmed"
                            value={item.confirmed}
                            onChange={this.handleChange}
                        >
                            <option value=''>وضعیت را انتخاب کنید</option>
                            <option value="unpaid">پرداخت نشده</option>
                            <option value="paid">پرداخت شده</option>
                            <option value="confirmed">تأیید شده</option>
                            <option value="reject">رد شده</option>
                        </Form.Control>
                    </Form.Group>
                    <Accordion>
                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey="0">
                                بازه ها
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="0">
                                <Card.Body>
                                    {period_message}
                                    {/*<Button variant="info" onClick={() => this.setState({show_add_period: true})}>افزودن بازه</Button>*/}
                                    <div className="table-responsive">
                                        <table className="table table-striped">
                                            <thead>
                                            <tr>
                                                <th>از تاریخ</th>
                                                <th>تا تاریخ</th>
                                                <th>عملیات</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {item.periods && item.periods.map(period =>
                                                <tr>
                                                    <td>{moment(period.from_date, "YYYY/MM/DD").format("jYYYY/jMM/jDD")}</td>
                                                    <td>{moment(period.to_date, "YYYY/MM/DD").format("jYYYY/jMM/jDD")}</td>
                                                    <td><span id={period.from_date} className="glyphicon glyphicon-trash link" onClick={(e) => this.removePeriod(e)}/></td>
                                                </tr>
                                            )}
                                            </tbody>
                                        </table>
                                    </div>
                                    <Modal show={show_add_period} onHide={this.handleHide} size="lg" aria-labelledby="contained-modal-title-lg">
                                        <Modal.Header closeButton>
                                            <Modal.Title>بازه</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <Form.Group>
                                                <Form.Label>از تاریخ</Form.Label>
                                                {period.from_date &&
                                                // <DatePicker
                                                //     inputComponent={this.DatePickerInput}
                                                //     placeholder="انتخاب تاریخ"
                                                //     format="jYYYY/jMM/jDD"
                                                //     onChange={this.changeFromDate}
                                                //     id="fDatePicker"
                                                //     preSelected={period.from_date ? moment(period.from_date, "YYYY/MM/DD HH:mm:ss").format("jYYYY/jMM/jDD") : ""}
                                                // />
                                                <DatePicker
                                                    onChange={this.changeFromDate}
                                                    value={period.from_date ?? ""}
                                                    isGregorian={false}
                                                    timePicker={false}
                                                />
                                                }
                                                {!period.from_date &&
                                                // <DatePicker
                                                //     inputComponent={this.DatePickerInput}
                                                //     placeholder="انتخاب تاریخ"
                                                //     format="jYYYY/jMM/jDD"
                                                //     onChange={this.changeFromDate}
                                                //     id="fDatePicker"
                                                //     preSelected={""}
                                                // />
                                                <DatePicker
                                                    onChange={this.changeFromDate}
                                                    value=""
                                                    isGregorian={false}
                                                    timePicker={false}
                                                />
                                                }
                                            </Form.Group>
                                            <Form.Group>
                                                <Form.Label>تا تاریخ</Form.Label>
                                                {period.to_date &&
                                                // <DatePicker
                                                //     inputComponent={this.DatePickerInput}
                                                //     placeholder="انتخاب تاریخ"
                                                //     format="jYYYY/jMM/jDD"
                                                //     onChange={this.changeToDate}
                                                //     id="tDatePicker"
                                                //     preSelected={period.to_date ? moment(period.to_date, "YYYY/MM/DD HH:mm:ss").format("jYYYY/jMM/jDD") : ""}
                                                // />
                                                <DatePicker
                                                    onChange={this.changeToDate}
                                                    value={period.to_date ? moment(period.to_date, "YYYY/MM/DD HH:mm:ss").format("jYYYY/jMM/jDD") : ""}
                                                    isGregorian={false}
                                                    timePicker={false}
                                                />
                                                }
                                                {!period.to_date &&
                                                // <DatePicker
                                                //     inputComponent={this.DatePickerInput}
                                                //     placeholder="انتخاب تاریخ"
                                                //     format="jYYYY/jMM/jDD"
                                                //     onChange={this.changeToDate}
                                                //     id="tDatePicker"
                                                //     preSelected={""}
                                                // />
                                                <DatePicker
                                                    onChange={this.changeToDate}
                                                    value=""
                                                    isGregorian={false}
                                                    timePicker={false}
                                                />
                                                }
                                            </Form.Group>
                                            <Button variant="primary" onClick={this.handleAddPeriod}>{period.id ? "ویرایش" : "افزودن"}</Button>
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <Button onClick={this.handleHide}>بستن</Button>
                                        </Modal.Footer>
                                    </Modal>
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    </Accordion>
                    <Button
                        type="submit"
                        disabled={submitLoading}
                        variant="primary"
                    >
                        {submitLoading ? loadingGif : 'ثبت'}
                    </Button>
                </form>
            </div>
        );
    }
}