import React from "react";
import Cookies from "../components/cookies";
import {Alert, Button, Form, Modal, Tab, Tabs, Table} from "react-bootstrap";
import {Loading, xhr} from "../components";
import ReactJson from "react-json-view";
import ReactHtmlParser from "react-html-parser";
import { withRouter } from "react-router-dom";

export const FuncContext = React.createContext({});
FuncContext.displayName = "FuncContext";

const adminNotes = [
    "تصویر انتخابی با موضوع کسب و کار و یا دسته‌بندی آن همخوانی ندارد.",
    "عنوان کسب و کار، متناسب با توضیحات و یا دسته‌بندی آن نمی‌باشد.",
    "موقعیت مکانی کسب و کار در نقشه با شهر مطابقت ندارد.",
];
const admin_note_header = "کاربر گرامی میز،\n" +
    "کسب و کار شما به دلایل زیر مورد تأیید قرار نگرفته است:\n" +
    "\n";
const admin_note_footer = "\n" +
    "لطفاً جهت تأیید کسب و کار خود، تغییرات لازم را اعمال بفرمایید.\n" +
    "با تشکر\n" +
    "پشتیبانی میز";

class FuncProvider extends React.Component {
    constructor(props) {
        super(props);
        this.fullName = props.props.fullName;
        this.state = {
            msg: {
                msgId: null,
                msgFrom: '',
                msgTo: '',
                msgText: '',
                msgResult: ''
            },
            msgLoading: false,
            setValue: this.setValue,
            modal: {
                title: '',
                body: '',
                show: false
            },
            admin_note: '',
            admin_notes: [],
            loadData: 0,
            functions: {
                showSendMessage: this.showSendMessage,
                changeURL: this.changeURL,
                showLogInfoAll: this.showLogInfoAll,
                showLogInfoWithoutOutput: this.showLogInfoWithoutOutput,
                mobileException: this.mobileException,
                deviceInformation: this.deviceInformation,
                newsButton: this.newsButton,
                showBiz: this.showBiz,
                buttonShowLink: this.buttonShowLink,
                adminNotes: this.adminNotes,
                claim: this.claim,
                handleEditComplex: this.handleEditComplex,
                handleFloors: this.handleFloors,
                permissionAllow: this.permissionAllow,
                permissionDocLimit: this.permissionDocLimit,
                permissionDetails: this.permissionDetails,
                updateDB: this.updateDB,
                goToCRM: this.goToCRM
            }
        };
        this.msgRef = React.createRef();
    }

    goToCRM = args => {
        window.open(`/crm/add/?service_id=${args.id}&service_title=${args.title}&phone=${args.phone}`);
    };

    updateDB = () => {
        console.log("update db");
        new xhr({url: 'update_promotions'}).GetMany(response => {
            console.log(response);
        });
    };

    showSendMessage = args => {
        let {msg, msgLoading} = this.state;
        const CookieObj = new Cookies();
        this.setState({msg: msg.changeBatch({
            msgId: args.id,
            msgFrom: CookieObj.getCookie('UNAME'),
            msgTo: this.fullName(args.name, args.family)
        })}, () => {
            this.setState({modal: {
                title: "ارسال پیام به " + msg.msgTo,
                body: <>
                    {msg.msgResult}
                    <Form.Group>
                        <Form.Control
                            as="textarea"
                            name="msg"
                            ref={this.msgRef}
                            onChange={this.changeMsg}
                            rows={5}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Button variant="primary" onClick={this.sendMsg} disabled={msgLoading}>{msgLoading ? <Loading/> : 'ارسال'}</Button>
                    </Form.Group>
                </>,
                show: true
            }});
        });
    };

    changeMsg = () => {
        this.setState({msg: this.state.msg.change('msgText', this.msgRef.current.value)});
    };

    sendMsg = () => {
        this.setState({msgLoading: true});
        let {msg} = this.state;
        const CookieObj = new Cookies();
        let data = {
            user_id: msg.msgId,
            user_name: msg.msgFrom,
            message: msg.msgText,
            from: CookieObj.getCookie('UID')
        };
        new xhr({
            url: 'chat',
            data
        }).Post(response => {
            let msgResult = '';
            if(response.status){
                msgResult = <Alert variant="success">پیام با موفقیت ارسال شد.</Alert>;
            }else{
                msgResult = <Alert variant="danger">{response.note}</Alert>
            }
            this.setState({
                msg: msg.change('msgResult', msgResult),
                msgLoading: false
            });
            setTimeout(() => {
                this.hideModal();
            }, 1000);
        });
    };

    changeURL = args => {
        let tokenPos = args[0].indexOf("token=");
        let result = args[0];
        if(tokenPos > -1){
            let startPart = args[0].substr(0, tokenPos + 6);
            let restPart = args[0].substr(tokenPos);
            let afterTokenPos = restPart.indexOf('&');
            if(afterTokenPos > -1){
                let endPart = args[0].substr(afterTokenPos + startPart.length - 6);
                result = startPart + "[TOKEN]" + endPart;
            }else{
                result = startPart + "[TOKEN]";
            }
        }
        result = decodeURI(result);
        return <a title={args[0]} href={"http://server1.onmiz.org/v2/" + args[0]} target="_blank" rel="noopener noreferrer">{result.substr(0, 100)}</a>;
    };

    showLogInfoAll = (args) => {
        if(!args.id) return;
        new xhr({
            url: 'logs',
            data: args.id,
        }).GetOne(item => {
            if(item){
                this.setState({modal: {
                    title: args.title,
                    body: <div className={'show-log-details'}>
                        <Tabs>
                            <Tab eventKey={1} title="پارامترها"><ReactJson src={item.params}/></Tab>
                            <Tab eventKey={2} title="خروجی"><ReactJson src={item.output}/></Tab>
                            <Tab eventKey={3} title="جزئیات"><ReactJson src={item.details}/></Tab>
                            <Tab eventKey={4} title="نتیجه"><ReactJson src={item.results}/></Tab>
                            <Tab eventKey={5} title="سند"><ReactJson src={item.doc}/></Tab>
                            <Tab eventKey={6} title="توکن">{item.token}</Tab>
                            <Tab eventKey={7} title="پارامترهای اولیه"><ReactJson src={item.original_params}/></Tab>
                        </Tabs>
                    </div>,
                    show: true
                }});
            }
        });
    };

    showLogInfoWithoutOutput = (args) => {
        if(!args.id) return;
        new xhr({
            url: 'logs',
            data: args.id,
        }).GetOne(item => {
            if(item){
                this.setState({modal: {
                    title: args.title,
                    body: <div className={'show-log-details'}>
                        <Tabs>
                            <Tab eventKey={1} title="پارامترها"><ReactJson src={item.params}/></Tab>
                            <Tab eventKey={2} title="خروجی"><ReactJson src={item.output}/></Tab>
                            <Tab eventKey={3} title="جزئیات"><ReactJson src={item.details}/></Tab>
                            <Tab eventKey={4} title="نتیجه"><ReactJson src={item.results}/></Tab>
                            <Tab eventKey={5} title="سند"><ReactJson src={item.doc}/></Tab>
                            <Tab eventKey={6} title="توکن">{item.token}</Tab>
                            <Tab eventKey={7} title="پارامترهای اولیه"><ReactJson src={item.original_params}/></Tab>
                        </Tabs>
                    </div>,
                    show: true
                }});
            }
        });
    };

    mobileException = args => <>{args[0].msg}<br/>{args[0].method}</>;

    deviceInformation = args => {
        this.setState({modal: {
            title: "اطلاعات دستگاه",
            body: args.device_info ?
                <Table striped responsive style={{direction: 'ltr'}}>
                    <tbody>
                    {Object.keys(args.device_info).map(key =>
                        <tr>
                            <td style={{textTransform: 'capitalize', textAlign: 'left'}}>{key}</td>
                            <td style={{textAlign: 'left'}}>{args.device_info[key]}</td>
                        </tr>
                    )}
                    </tbody>
                </Table>
                :
                "",
            show: true
        }});
    };

    newsButton = args => {
        return <Button onClick={() => this.showNews(args[0])}>متن خبر</Button>;
    };

    showNews = news => {
        this.setState({modal: {
            title: "متن خبر",
            body: ReactHtmlParser(news),
            show: true
        }});
    };

    showBiz = args => {
        return args[2] ?
            <Button
                // onClick={() => window.open("https://onmiz.com/biz/" + args[1])}
                onClick={() => window.open("https://onmiz.com/details/" + args[0])}
                style={{wordBreak: 'normal'}}
            >
                نمایش
            </Button>
            :
            null;
    };

    buttonShowLink = args => {
        if(!args[0]) return;
        return <Button variant="outline-info" onClick={() => this.setState({modal: {
            title: 'لینک دکمه',
            body: args[1],
            show: true
        }})}>{args[0]}</Button>;
    };

    setValue = val => {
        this.setState(val);
    };

    hideModal = () => this.setState({
        modal: {
            title: '',
            body: '',
            show: false
        },
        msg: {
            msgId: null,
            msgFrom: '',
            msgTo: '',
            msgText: '',
            msgResult: ''
        }
    });

    handleChangeAdminNotes = (event, index, fieldChange) => {
        let target = event.target;
        let {admin_notes} = this.state;
        admin_notes[index] = target.checked;
        let admin_note = admin_note_header;
        for(let i = 0; i < admin_notes.length; i++){
            if(admin_notes[i]){
                admin_note += "- " + adminNotes[i] + "\n";
            }
        }
        admin_note += admin_note_footer;
        this.setState({admin_notes});
        fieldChange({
            target: {
                value: admin_note
            }
        });
    };

    adminNotes = args => {
        this.setState({admin_note: args.admin_note});
        return <>
            <Form.Group>
                {adminNotes.map((adminNote, index) =>
                    <Form.Check
                        type="checkbox"
                        key={`admin-note-${index}`}
                        checked={this.state.admin_notes[index]}
                        onChange={(e) => this.handleChangeAdminNotes(e, index, args.fieldChange)}
                        label={adminNote}
                        id={`checkbox-${index}`}
                    />
                )}
            </Form.Group>
            <Form.Group>
                <Form.Control
                    as="textarea"
                    rows={10}
                    name="admin_note"
                    value={args.admin_note}
                    onChange={args.fieldChange}
                />
            </Form.Group>
        </>
    };

    claim = args => {
        let ans = window.confirm("آیا مطمئنید؟");
        if(!ans) return;
        if(args.id && args.service_id){
            args.claim_id = args.id;
            delete args["id"];
            new xhr({
                url: 'claims',
                data: args
            }).Put(response => {
                if(response.status){
                    this.setState({loadData: this.state.loadData + 1});
                }
            });
        }
    };

    handleEditComplex = args => {
        window.open(`/businesses/edit/${args.id}`);
    };

    handleFloors = args => {
        let {history} = this.props;
        if(history){
            history.push(`/complex_floors/${args.id}`);
        }else{
            window.location = `/complex_floors/${args.id}`;
        }
    };

    permissionAllow = args => {
        return JSON.stringify(args[0].allow);
    };

    permissionDocLimit = args => {
        return args[0].doc_limit;
    };

    permissionDetails = args => {
        return "Get : " + JSON.stringify(args[0].get) + " - Post : " + JSON.stringify(args[0].post) + " - Put : " + JSON.stringify(args[0].put) + " - Delete : " + JSON.stringify(args[0].delete);
    };

    render() {
        let {modal} = this.state;
        return (
            <FuncContext.Provider value={this.state}>
                <Modal show={modal.show} onHide={this.hideModal} size="lg">
                    <Modal.Header closeButton>
                        <Modal.Title>{modal.title}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{modal.body}</Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-primary" onClick={this.hideModal}>بستن</Button>
                    </Modal.Footer>
                </Modal>
                {this.props.children}
            </FuncContext.Provider>
        );
    }
}

export default withRouter(FuncProvider);