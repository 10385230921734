const articles = props => {
    return {
        base: {
            entities: "مطالب",
            entity: "مطلب",
            module: "articles",
            path: "/articles",
            model: {
                pic: {title: "تصویر", type: "image"},
                title: {title: "عنوان"},
                content: {title: "مطلب"},
                user_id: {
                    title: "نویسنده",
                    // type: "foreign",
                    // foreign: {
                    //     module: "users",
                    //     path: "/users",
                    //     field: "id",
                    // }
                },
            },
        },
        list: {
            page: props.page,
            fields: [
                {name: "pic"},
                {name: "title"},
                {name: "content", max_length: 100},
                {
                    name: "user_id",
                    // result: [
                    //     {name: "name"},
                    //     {type: "static", value: " "},
                    //     {name: "family"}
                    // ]
                },
                {name: "create_date", title: "تاریخ", type: "date"},
                {name: "create_date", title: "زمان", type: "time"},
            ],
            operations: ["add", "edit", "remove"]
        },
        item: {
            id: props.id,
            fields: [
                {name: "pic"},
                {name: "title"},
                {name: "content", type: "textarea"},
                {name: "user_id"},
            ],
        },
    };
};

export default articles;