import React, {useEffect, useState} from "react";
import {Form, Button, Alert} from 'react-bootstrap';
import xhr from '../components/xhr';

export default function LotteryCodes() {
    const [item, setItem] = useState({
        gift_id: '',
        prefix: '',
        count: '',
        preview: true
    });
    const [gifts, setGifts] = useState([]);
    const [codes, setCodes] = useState([]);
    const [message, setMessage] = useState(null);

    const generate = () => {
        new xhr({
            url: 'generate_lottery_codes',
            data: item
        }).Post(response => {
            if(response.status){
                setCodes(response.data.list.map(item => item.data));
            }else{
                setMessage(<Alert variant={"danger"}>{response.note}</Alert>);
            }
        });
    };

    const save = () => {
        let data = {...item};
        Reflect.deleteProperty(data, 'preview');
        new xhr({
            url: 'generate_lottery_codes',
            data
        }).Post(response => {
            if(response.status){
                setMessage(<Alert variant={"success"}>کدها با موفقیت ثبت شدند</Alert>);
            }else{
                setMessage(<Alert variant={"danger"}>{response.note}</Alert>);
            }
        });
    };

    const handleChange = e => {
        let {target} = e;
        setItem({...item, [target.name]: target.value});
    };

    const getGifts = () => {
        new xhr({url: 'gifts', data: {sort: {_id: -1}}}).GetMany(response => {
            if(response.status){
                setGifts(response.data.list);
            }
        });
    };

    useEffect(getGifts, []);

    return <div>
        <h2>تولید کد قرعه کشی</h2>
        {message}
        <Form.Group>
            <Form.Label>هدیه</Form.Label>
            <Form.Control
                as={"select"}
                name={"gift_id"}
                value={item.gift_id}
                onChange={handleChange}
            >
                <option value={''}>-</option>
                {gifts && gifts.map(gift =>
                    <option value={gift.id}>{gift.title} - {gift.data}</option>
                )}
            </Form.Control>
        </Form.Group>
        <Form.Group>
            <Form.Label>پیشوند</Form.Label>
            <Form.Control
                name={"prefix"}
                value={item.prefix}
                onChange={handleChange}
            />
        </Form.Group>
        <Form.Group>
            <Form.Label>تعداد</Form.Label>
            <Form.Control
                name={"count"}
                value={item.count}
                onChange={handleChange}
            />
        </Form.Group>
        <Form.Group>
            <Button variant={"info"} onClick={generate}>تولید</Button>
            {codes && codes.length > 0 &&
                <>
                    {' '}
                    <Button variant={"primary"} onClick={save}>ثبت</Button>
                </>
            }
        </Form.Group>
        <div>
            {codes && codes.length > 0 && codes.join(', ')}
        </div>
    </div>;
}