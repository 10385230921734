import React, {useContext, useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {Form, Button, Alert} from "react-bootstrap";
import {Context} from "../providers/mainProvider";
import {Loading, xhr} from "../components";

export default function Permissions(props) {
    const [message, setMessage] = useState('');
    const [item, setItem] = useState({
        id: null,
        module: '',
        allow_get: false,
        allow_post: false,
        allow_put: false,
        allow_delete: false,
        get_json: JSON.stringify({}),
        post_json: JSON.stringify({}),
        put_query_json: JSON.stringify({}),
        put_set_json: JSON.stringify({}),
        delete_json: JSON.stringify({}),
        doc_limit: -1
    });
    const [loading, setLoading] = useState(false);
    const [submitLoading, setSubmitLoading] = useState(false);
    const {config} = useContext(Context);
    const url = "permissions";
    let id = props.match.params.id;

    const handleChange = e => {
        let {target} = e;
        let {value} = target;
        if(target.type === "checkbox"){
            value = target.checked;
        }
        setItem(item.change(target.name, value));
    };

    const showResult = response => {
        if(response.status){
            setMessage(<Alert variant="success">{response.note}</Alert>);
        }else{
            setMessage(<Alert variant="danger">{response.note}</Alert>);
        }
        window.scroll(0, 0);
        setSubmitLoading(false);
        let post_item = item;
        post_item.get_json = JSON.stringify(post_item.get_json);
        post_item.post_json = JSON.stringify(post_item.post_json);
        post_item.put_query_json = JSON.stringify(post_item.put_query_json);
        post_item.put_set_json = JSON.stringify(post_item.put_set_json);
        post_item.delete_json = JSON.stringify(post_item.delete_json);
        setItem(post_item);
    };

    const handleSubmit = event => {
        event.preventDefault();
        setSubmitLoading(true);
        let data = {...item};
        try {
            data.get_json = data.get_json ? JSON.parse(data.get_json) : {};
            data.post_json = data.post_json ? JSON.parse(data.post_json) : {};
            data.put_query_json = data.put_query_json ? JSON.parse(data.put_query_json) : {};
            data.put_set_json = data.put_set_json ? JSON.parse(data.put_set_json) : {};
            data.delete_json = data.delete_json ? JSON.parse(data.delete_json) : {};
            if(id){
                data.id = id;
                new xhr({
                    url,
                    data
                }).Put(showResult);
            }else{
                new xhr({
                    url,
                    data
                }).Post(showResult);
            }
        }catch (e) {
            setSubmitLoading(false);
            setMessage(<Alert variant="danger">{e.message}</Alert>);
        }
    };

    useEffect(() => {
        if(id){
            setLoading(true);
            new xhr({
                url,
                data: id
            }).GetOne(response => {
                setLoading(false);
                let response_item = {
                    id: response.id,
                    name: response.name,
                    module: response.module,
                    doc_limit: response.permissions.doc_limit,
                    get_json: response.permissions.get ? JSON.stringify(response.permissions.get) : JSON.stringify({}),
                    post_json: response.permissions.post ? JSON.stringify(response.permissions.post) : JSON.stringify({}),
                    put_query_json: response.permissions.put && response.permissions.put.query ? JSON.stringify(response.permissions.put.query): JSON.stringify({}),
                    put_set_json: response.permissions.put && response.permissions.put.set ? JSON.stringify(response.permissions.put.set) : JSON.stringify({}),
                    delete_json: response.permissions.delete ? JSON.stringify(response.permissions.delete) : JSON.stringify({}),
                };
                response_item.allow_get = response.permissions.allow.indexOf("get") > -1;
                response_item.allow_post = response.permissions.allow.indexOf("post") > -1;
                response_item.allow_put = response.permissions.allow.indexOf("put") > -1;
                response_item.allow_delete = response.permissions.allow.indexOf("delete") > -1;
                setItem(response_item);
            });
        }
    }, []);

    return (
        <div>
            <h2>{id ? "ویرایش دسترسی" : "افزودن دسترسی"}</h2>
            <Link to="/permissions">دسترسی ها</Link>
            <p>&nbsp;</p>
            {loading ?
                <Loading/>
                :
                <Form onSubmit={handleSubmit}>
                    {message}
                    <Form.Group>
                        <Form.Label>نقش</Form.Label>
                        <Form.Control
                            as="select"
                            name="name"
                            value={item.name}
                            onChange={handleChange}
                        >
                            <option value=''>نقش را انتخاب کنید</option>
                            {config.ROLES && config.ROLES.map(role =>
                                <option value={role.key}>{role.value}</option>
                            )}
                        </Form.Control>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>موجودیت</Form.Label>
                        <Form.Control
                            type="text"
                            name="module"
                            value={item.module}
                            onChange={handleChange}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>محدودیت تعداد</Form.Label>
                        <Form.Control
                            type="number"
                            name="doc_limit"
                            value={item.doc_limit}
                            onChange={handleChange}
                            style={{direction: "ltr"}}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>دسترسی ها</Form.Label>
                        <Form.Check name="allow_get" checked={item.allow_get} onChange={handleChange} id="checkbox-Get" label="Get" />
                        <Form.Check name="allow_post" checked={item.allow_post} onChange={handleChange} id="checkbox-Post" label="Post" />
                        <Form.Check name="allow_put" checked={item.allow_put} onChange={handleChange} id="checkbox-Put" label="Put" />
                        <Form.Check name="allow_delete" checked={item.allow_delete} onChange={handleChange} id="checkbox-Delete" label="Delete" />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Get Json</Form.Label>
                        <Form.Control
                            type="text"
                            name="get_json"
                            value={item.get_json}
                            onChange={handleChange}
                            style={{direction: 'rtl', textAlign: 'left'}}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Post Json</Form.Label>
                        <Form.Control
                            type="text"
                            name="post_json"
                            value={item.post_json}
                            onChange={handleChange}
                            style={{direction: 'rtl', textAlign: 'left'}}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Put Query Json</Form.Label>
                        <Form.Control
                            type="text"
                            name="put_query_json"
                            value={item.put_query_json}
                            onChange={handleChange}
                            style={{direction: 'rtl', textAlign: 'left'}}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Put Set Json</Form.Label>
                        <Form.Control
                            type="text"
                            name="put_set_json"
                            value={item.put_set_json}
                            onChange={handleChange}
                            style={{direction: 'rtl', textAlign: 'left'}}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Delete Json</Form.Label>
                        <Form.Control
                            type="text"
                            name="delete_json"
                            value={item.delete_json}
                            onChange={handleChange}
                            style={{direction: 'rtl', textAlign: 'left'}}
                        />
                    </Form.Group>
                    <Button
                        type="submit"
                        disabled={submitLoading}
                        variant="primary"
                    >
                        {submitLoading ? <Loading theme="light"/> : 'ثبت'}
                    </Button>
                </Form>
            }
        </div>
    );
}