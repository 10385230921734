const sponsor_packages_orders = props => {
    return {
        base: {
            entities: "سفارشات آگهی اسپانسری",
            entity: "سفارش آگهی اسپانسری",
            module: "sponsor_packages_orders",
            path: "/sponsor_packages_orders",
            model: {
                image: {title: "تصویر", type: "image"},
                sponsor_package_id: {
                    title: "نوع آگهی اسپانسری",
                    type: "select_data",
                    foreign: {
                        module: "sponsor_packages",
                        path: "sponsor_packages",
                        field: "id",
                        result: ["title"]
                    }
                },
                periods: {title: "دوره"},
                price: {title: "قیمت"},
                type: {
                    title: "نوع",
                    type: "radio",
                    items: [
                        {key: "list", value: "لیست"},
                        {key: "banner", value: "بنر"}
                    ]
                },
                confirmed: {
                    title: "وضعیت",
                    type: "select",
                    items: [
                        {key: "unpaid", value: "پرداخت نشده"},
                        {key: "paid", value: "پرداخت شده"},
                        {key: "confirmed", value: "تأیید شده"},
                        {key: "reject", value: "رد شده"},
                    ]
                },
                user_id: {
                    title: "کاربر",
                    type: "picker",
                    module: "users",
                    path: "users",
                    caption: ""
                },
                service_id: {
                    title: "کسب و کار",
                    type: "picker",
                    module: "services",
                    path: "businesses",
                    caption: ""
                },
                category_id: {
                    title: "دسته بندی",
                    type: "picker",
                    module: "categories",
                    path: "categories",
                    caption: ""
                },
                city_id: {
                    title: "شهر",
                    type: "picker",
                    module: "cities",
                    path: "cities",
                    caption: ""
                },
            }
        },
        list: {
            page: props.page,
            fields: [
                {name: "user_id"},
                {name: "service_id"},
                {name: "image"},
                {name: "sponsor_package_id"},
                {name: "category_id"},
                {name: "city_id"},
                {name: "periods", type: "json", style: {direction: "ltr", textAlign: "left"}},
                {name: "price", type: "formatted_number"},
                {name: "type"},
                {name: "confirmed"}
            ],
            operations: ["add", "edit", "remove"]
        },
        item: {
            id: props.id,
            fields: [
                {name: "sponsor_package_id"},
                {name: "city_id"},
                {name: "category_id"},
                {name: "periods", type: "json"},
                {name: "price"},
                {name: "type"},
                {name: "confirmed"}
            ]
        }
    };
};

export default sponsor_packages_orders;