import React, {useEffect, useState} from "react";
import {Cookies, Loading, xhr} from "../components";
import ReactChartkick, { AreaChart } from 'react-chartkick';
import Chart from 'chart.js';
import {
    Container,
    Row,
    Col,
    Button,
    ButtonGroup,
    Accordion,
    Card,
    Form,
    Alert
} from "react-bootstrap";

export default function Report (props) {
    let initialChart = JSON.parse(localStorage.getItem("chart"));

    const [chartData, setChartData] = useState(null);
    const [chartName, setChartName] = useState('');
    const [chart, setChart] = useState(initialChart ?? {
        report: 'alu',
        type: 'monthly',
        amount: 10,
        min: 0,
        max: 10
    });
    const [loading, setLoading] = useState(false);
    const [noResult, setNoResult] = useState(false);

    const getReport = props => {
        let data = {
            report: chart.report,
            type: props ? props.type ?? chart.type : chart.type,
            amount: props ? props.amount ?? chart.amount : chart.amount
        };
        setLoading(true);
        setNoResult(false);
        let cookie = new Cookies();
        let token = cookie.getCookie("log_token");
        new xhr({
            url: 'dashboard',
            data,
            perPage: 1000000,
            server: 'log',
            token
        }).GetMany(response => {
            setLoading(false);
            if(response.status){
                let list = response.data.list;
                if(list && list.length > 0){
                    let min = list[0].cumulative;
                    let max = list[list.length - 1].cumulative;
                    setChart(chart.changeBatch({min, max}));
                    localStorage.setItem("chart", JSON.stringify(chart));
                    let first_chart_data = {};
                    let dates = [];
                    for (let i = 0; i < list.length; i++) {
                        switch(chart.type){
                            case "daily":
                                // list[i].date = list[i].date ? moment(list[i].date, "YYYY-MM-DD").format("jYYYY/jMM/jDD") : '';
                                break;
                            case "weekly":
                                list[i].date = list[i].date.year + " هفته " + list[i].date.week;
                                break;
                            case "monthly":
                                if(list[i].date.month < 10){
                                    list[i].date.month = "0" + list[i].date.month;
                                }
                                list[i].date = list[i].date.year + "-" + list[i].date.month + "-01";
                                break;
                            default:
                                break;
                        }
                        dates.push(list[i].date);
                        first_chart_data[list[i].date] = list[i].cumulative;
                    }
                    dates = dates.reverse();
                    let chart_data = {};
                    for(let i = 0; i < dates.length; i++){
                        chart_data[dates[i]] = first_chart_data[dates[i]];
                    }
                    setChartData(chart_data);
                    localStorage.setItem("chart_data", JSON.stringify(chart_data));
                }else{
                    setNoResult(true);
                }
            }
        });
    };

    const changePeriod = period => {
        setChart(chart.change('type', period));
        getReport({type: period});
    };

    const changeAmount = e => {
        let {target} = e;
        if(!target.value || target.value < 1){
            target.value = 1;
        }
        setChart(chart.change('amount', target.value));
    };

    const enterNumber = e => {
        e.preventDefault();
        getReport();
    };

    useEffect(() => {
        let chart_data = localStorage.getItem("chart_data");
        if(chart_data){
            setChartData(JSON.parse(chart_data));
        }
        if(props.match.params){
            let chart = props.match.params;
            chart.min = 0;
            chart.max = 1;
            setChart(chart);
            getReport();
        }else{
            getReport();
        }
        setInterval(getReport, 1000 * 60 * 30);
    }, []);

    return (
        <Container>
            <Row>
                <Col>
                    <Accordion>
                        <Card>
                            <Card.Header>نمودار {chartName}</Card.Header>
                            <Card.Body>
                                {chartData ?
                                    <Container>
                                        <Row>
                                            <Col>
                                                <ButtonGroup>
                                                    <Button onClick={() => changePeriod('monthly')} variant={chart.type === 'monthly' ? 'primary' : 'light'}>ماهانه</Button>
                                                    <Button onClick={() => changePeriod('weekly')} variant={chart.type === 'weekly' ? 'primary' : 'light'}>هفتگی</Button>
                                                    <Button onClick={() => changePeriod('daily')} variant={chart.type === 'daily' ? 'primary' : 'light'}>روزانه</Button>
                                                </ButtonGroup>
                                            </Col>
                                            <Col>
                                                <Form onSubmit={enterNumber}>
                                                    <Form.Row>
                                                        <Col xs="auto">
                                                            <Form.Control
                                                                type="number"
                                                                name="number"
                                                                value={chart.amount}
                                                                onChange={changeAmount}
                                                            />
                                                        </Col>
                                                        <Col xs="auto">
                                                            <Button type="submit">اعمال</Button>
                                                        </Col>
                                                    </Form.Row>
                                                </Form>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <div className="chart-container">
                                                    {loading &&
                                                    <div className="chart-overlay">
                                                        <Loading width={60} height={60}/>
                                                    </div>
                                                    }
                                                    {noResult &&
                                                    <div className="chart-overlay">
                                                        <Alert variant="danger" style={{width: 'fit-content'}}>در این بازه زمانی نتیجه ای پیدا نشد</Alert>
                                                    </div>
                                                    }
                                                    <AreaChart data={(loading || noResult) ? {} : chartData} min={chart.min} max={chart.max} />
                                                </div>
                                            </Col>
                                        </Row>
                                    </Container>
                                    :
                                    <Loading/>
                                }
                            </Card.Body>
                        </Card>
                    </Accordion>
                </Col>
            </Row>
        </Container>
    );
}