const permissions = props => {
    return {
        base: {
            entities: "دسترسی ها",
            entity: "دسترسی",
            module: "permissions",
            path: "/permissions",
            model: {
                module: {title: "ماژول"},
                name: {
                    title: "نقش",
                    type: "select",
                    items: props.config ? props.config.ROLES : []
                },
                permissions: {title: "دسترسی ها"},
            }
        },
        list: {
            page: props.page,
            fields: [
                {name: "name"},
                {name: "module"},
                {
                    name: "permissions",
                    style: {direction: "ltr"},
                    type: "function",
                    value: {
                        func: props.functions.permissionAllow,
                        params: ["permissions"]
                    }
                },
                {
                    title: "تعداد محدودیت",
                    style: {direction: "ltr"},
                    type: "function",
                    value: {
                        func: props.functions.permissionDocLimit,
                        params: ["permissions"]
                    }
                },
                {
                    title: "جزئیات",
                    style: {direction: "ltr", textAlign: "left"},
                    type: "function",
                    value: {
                        func: props.functions.permissionDetails,
                        params: ["permissions"]
                    }
                },
            ],
            search: [
                {
                    name: "name",
                    field_type: "select",
                    source_data: props.config ? props.config.ROLES : []
                },
                {name: "module"}
            ],
            operations: ["add", "edit", "remove"]
        },
        item: {
            id: props.id,
            fields: [
                {name: "name"},
                {name: "module"}
            ]
        }
    };
};

export default permissions;