const versions = props => {
    return {
        base: {
            entities: "نسخه ها",
            entity: "نسخه",
            module: "versions",
            path: "/versions",
            model: {
                version: {title: "نسخه"},
                type: {
                    title: "نوع",
                    type: "radio",
                    items: [
                        {key: "optional", value: "اختیاری"},
                        {key: "forced", value: "اجباری"}
                    ]
                },
                app_type: {
                    title: "نوع اپ",
                    type: "radio",
                    items: [
                        {key: "android", value: "Android"},
                        {key: "ios", value: "iOS"}
                    ]
                },
                description: {title: "توضیحات"},
            }
        },
        list: {
            page: props.page,
            fields: [
                {name: "version"},
                {name: "type"},
                {name: "description"},
                {name: "create_date", title: "تاریخ", type: "date"},
                {name: "create_date", title: "زمان", type: "time"},
            ],
            operations: ["add", "edit", "remove"]
        },
        item: {
            id: props.id,
            fields: [
                {name: "version"},
                {name: "type"},
                {name: "app_type"},
                {name: "description"},
            ],
        },
    };
};

export default versions;