const billboards = props => {
    return {
        base: {
            entities: "بیلبوردهای مجازی",
            entity: "بیلبورد مجازی",
            module: "billboards",
            path: "/billboards",
            model: {
                logo: {title: "تصویر", type: "image", width: 820, height: 456},
                service_id: {
                    title: "کسب و کار",
                    type: "picker",
                    module: "services",
                    caption: ["title"]
                },
                title: {title: "عنوان"},
                description: {title: "توضیحات"},
                link: {title: "لینک"},
                from_date: {title: "شروع", type: "date"},
                to_date: {title: "پایان", type: "date"},
                default: {title: "پیشفرض", type: "checkbox"},
                location: {title: "موقعیت", type: "map", radius: "radius"},
                radius: {title: "شعاع", afterChange: true},
                login_required: {title: "نیازمند ورود", type: "checkbox"}
            }
        },
        list: {
            page: props.page,
            fields: [
                {name: "logo"},
                {name: "service_id"},
                {name: "radius"},
                {name: "title"},
                {name: "description", max_length: 50},
                {name: "link"},
                {name: "from_date"},
                {name: "to_date"},
                {name: "default"},
                {name: "login_required"}
            ],
            operations: ["add", "edit", "remove"]
        },
        item: {
            id: props.id,
            fields: [
                {name: "logo"},
                {name: "service_id"},
                {name: "title"},
                {name: "description", type: "textarea"},
                {name: "link"},
                {name: "location"},
                {name: "radius"},
                {name: "from_date"},
                {name: "to_date"},
                {name: "default"},
                {name: "login_required", default: false}
            ],
        },
    };
};

export default billboards;