import React, {useContext, useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {xhr, Loading, Cookies} from "../components";
import {Alert, Button, Form} from "react-bootstrap";

const reports = [
    {title: "کل کاربران", code: "", name: "all_users"},
    {title: "ثبت نام ها", code: "alu", name: "all_registered_users"},
    {title: "کاربران مهمان", code: "", name: "guest_users"},
    {title: "کاربران ثبت نام کرده که اپ را نصب کرده اند", code: "nu", name: "normal_users"},
    {title: "کاربرانی که با کد معرف عضو شدند", code: "nuwr", name: "normal_users_with_refcode"},
    {title: "کاربرانی که بدون کد معرف عضو شدند", code: "nuwur", name: "normal_users_without_refcode"},
    {title: "کاربرانی که پروفایل خود را تکمیل کرده اند", code: "nucp", name: "normal_users_complete_profile"},
    {title: "کاربرانی که پروفایل خود را تکمیل نکرده اند", code: "nuncp", name: "normal_users_no_complete_profile"},
    {title: "کاربرانی که با موبایل عضو شده اند", code: "urwn", name: "user_register_with_number"},
    {title: "کاربرانی که با ایمیل عضو شده اند", code: "urwun", name: "user_register_without_number"},
    {title: "کاربران بدون کسب و کار که با موبایل عضو شده اند", code: "prhns", name: "phone_register_has_no_service"},
    {title: "کاربران بدون کسب و کار که با ایمیل عضو شده اند", code: "erhns", name: "email_register_has_no_service"},
    {title: "صاحبین کسب و کار که با موبایل عضو شده اند", code: "prhs", name: "phone_register_has_service"},
    {title: "صاحبین کسب و کار که با ایمیل عضو شده اند", code: "erhs", name: "email_register_has_service"},
    {title: "صاحبین کسب و کار حاشیه که با موبایل عضو شده اند", code: "ncsp", name: "no_complex_service_with_phone_register"},
    {title: "صاحبین کسب و کار حاشیه که با ایمیل عضو شده اند", code: "ncse", name: "no_complex_service_with_email_register"},
    {title: "صاحبین کسب و کار داخل مجتمع که با موبایل عضو شده اند", code: "csp", name: "complex_service_with_phone_register"},
    {title: "صاحبین کسب و کار داخل مجتمع که با ایمیل عضو شده اند", code: "cse", name: "complex_service_with_email_register"}
];

export default function ReportDashboard (props) {
    const [report, setReport] = useState(null);
    const [message, setMessage] = useState(null);
    const [item, setItem] = useState({
        mobile: '',
        password: ''
    });
    const [loading, setLoading] = useState(true);
    const [submitLoading, setSubmitLoading] = useState(false);
    const [auth, setAuth] = useState(false);

    const getReport = () => {
        let cookie = new Cookies();
        let token = cookie.getCookie("log_token");
        new xhr({
            url: 'dashboard',
            server: 'log',
            token
        }).GetOne(response => {
            console.log(response);
            setLoading(false);
            if(response){
                if(response.status === false){
                    setAuth(false);
                }else{
                    setReport(response);
                    setAuth(true);
                    localStorage.setItem("report", JSON.stringify(response));
                }
            }
        });
    };

    const login = e => {
        e.preventDefault();
        setSubmitLoading(true);
        new xhr({
            url: 'login',
            data: item,
            no_token: true,
            no_locale: true,
            server: 'log'
        }).Post(response => {
            if(response.status){
                let cookie = new Cookies();
                cookie.setCookie({
                    name: "log_token",
                    value: response.token,
                    days: 30
                });
                setAuth(true);
                getReport();
            }else{
                setMessage(<Alert variant="danger">نام کاربری یا رمز عبور اشتباه است.</Alert>);
            }
            setSubmitLoading(false);
        });
    };

    const handleChange = e => {
        let {target} = e;
        setItem(item.change(target.name, target.value));
    };

    const formatNum = num => {
        return new Intl.NumberFormat().format(num);
    };

    useEffect(() => {
        let report = localStorage.getItem("report");
        if(report){
            setReport(JSON.parse(report));
        }
        setInterval(getReport, 1000 * 60 * 30); // 30min
        getReport();
    }, []);

    return (
        <div>
            <h2>گزارشات کلی میز</h2>
            {loading ?
                <Loading/>
                :
                auth ?
                    report ?
                        <table className="table table-striped">
                            <tbody>
                            {reports.map(rep =>
                                <tr>
                                    <td>
                                        {rep.code ?
                                            <Link to={`/report/${rep.code}/monthly/10`}>{rep.title}</Link>
                                            :
                                            rep.title
                                        }
                                    </td>
                                    <td>
                                        {rep.code ?
                                            <Link to={`/report/${rep.code}/monthly/10`}>{formatNum(report[rep.name])}</Link>
                                            :
                                            formatNum(report[rep.name])
                                        }
                                    </td>
                                </tr>
                            )}
                            <tr>
                                <td>اپراتورها</td>
                                <td>
                                    <Link to={`/report/simir/monthly/10`}>همراه اول : {report.sim_cards_operators && formatNum(report.sim_cards_operators["MCI-IR"])}</Link> ،
                                    <Link to={`/report/simirn/monthly/10`}>ایرانسل : {report.sim_cards_operators && formatNum(report.sim_cards_operators["Irancell"])}</Link> ،
                                    <Link to={`/report/simright/monthly/10`}>رایتل : {report.sim_cards_operators && formatNum(report.sim_cards_operators["RighTel"])}</Link> ،
                                    <Link to={`/report/simta/monthly/10`}>تالیا : {report.sim_cards_operators && formatNum(report.sim_cards_operators["Taliya"])}</Link>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        :
                        <Loading/>
                    :
                    <div className="login-box centered">
                        {message}
                        <form onSubmit={login}>
                            <Form.Group>
                                <Form.Label>نام کاربری</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="mobile"
                                    value={item.mobile}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>رمز عبور</Form.Label>
                                <Form.Control
                                    type="password"
                                    name="password"
                                    value={item.password}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                            <Button type="submit" bsStyle="primary" disabled={submitLoading}>{submitLoading ? <Loading theme="light"/> : 'ورود'}</Button>
                        </form>
                    </div>
            }
        </div>
    );
}
