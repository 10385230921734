const pins = props => {
    return {
        base: {
            entities: "پین های ویژه",
            entity: "پین ویژه",
            module: "pin_promotions",
            path: "/pin_promotions",
            model: {
                service_id: {
                    title: "کسب و کار",
                    type: "picker",
                    module: "services",
                    path: "businesses",
                    caption: "title"
                },
                pin_url: {title: "پین", type: "image", width: 45, height: 45},
                pin_hover_url: {title: "پین انتخاب شده", type: "image", width: 45, height: 45},
                from_date: {title: "شروع", type: "date"},
                to_date: {title: "پایان", type: "date"},
            }
        },
        list: {
            page: props.page,
            fields: [
                {name: "service_id"},
                {name: "from_date"},
                {name: "to_date"},
                {name: "pin_url"},
                {name: "pin_hover_url"},
            ],
            operations: ["add", "edit", "remove"]
        },
        item: {
            id: props.id,
            fields: [
                {name: "service_id"},
                {name: "from_date"},
                {name: "to_date"},
                {name: "pin_url"},
                {name: "pin_hover_url"},
            ]
        }
    };
};

export default pins;