const gift_codes = props => {
    return {
        base: {
            entities: "کدهای هدیه",
            entity: "کد هدیه",
            module: "gift_codes",
            path: "/gift_codes",
            model: {
                code: {title: "کد"},
                user_id: {
                    title: "کاربر",
                    type: "picker",
                    module: "users",
                    path: "users",
                    caption: ""
                },
                company: {
                    title: "شرکت",
                    type: "select",
                    items: [
                        {key: "MTN", value: "ایرانسل"},
                        {key: "MCI", value: "همراه اول"},
                        {key: "RTL", value: "رایتل"}
                    ]
                },
                value: {title: "مقدار"},
                used: {
                    title: "وضعیت",
                    type: "radio",
                    items: [
                        {key: true, value: "منقضی"},
                        {key: false, value: "استفاده نشده"},
                    ]
                },
                type: {
                    title: "نوع",
                    type: "radio",
                    items: [
                        {key: "charge", value: "شارژ"}
                    ]
                },
            }
        },
        list: {
            page: props.page,
            fields: [
                {name: "code"},
                {name: "user_id"},
                {name: "company"},
                {name: "value", type: "formatted_number"},
                {name: "used"},
                {name: "type"},
            ],
            operations: ["add", "edit", "remove"]
        },
        item: {
            id: props.id,
            fields: [
                {name: "code"},
                {name: "user_id"},
                {name: "company"},
                {name: "value"},
                {name: "used"},
                {name: "type"},
            ]
        }
    };
};

export default gift_codes;