import React, {useEffect, useState} from 'react';
import importCharges from "../models/importCharges";
import Item from "../components/item";

export default function ImportChanges(props) {
    const [entities, setEntities] = useState({});

    useEffect(() => {
        setEntities(importCharges());
    }, []);

    return (
        <div>
            {entities.base ?
                <Item
                    props={{
                        base: entities.base,
                        item: entities.item
                    }}
                />
                : ''
            }
        </div>
    );
}