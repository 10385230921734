const plugins = props => {
    return {
        base: {
            entities: "ابزارک ها",
            entity: "ابزارک",
            module: "plugins",
            path: "/plugins",
            model: {
                name: {title: "نام"},
                internal_link: {title: "لینک داخلی"},
                external_link: {title: "لینک خارجی"},
                icon: {title: "آیکن", type: "simple_image"},
                border_color: {title: 'رنگ حاشیه'},
                bg_color: {title: 'رنگ پس زمینه'},
                data: {title: "دیتا"},
                login_required: {title: "نیازمند ورود", type: "checkbox"},
                order: {title: "ترتیب"}
            }
        },
        list: {
            page: props.page,
            fields: [
                {name: "icon"},
                {name: "name"},
                {name: "internal_link"},
                {name: "external_link"},
                {name: "data"},
                {name: "login_required"},
                {name: "order"}
            ],
            operations: ["add", "edit", "remove"]
        },
        item: {
            id: props.id,
            fields: [
                {name: "icon"},
                {name: "name"},
                {name: "internal_link"},
                {name: "external_link"},
                {name: "data"},
                {name: "border_color"},
                {name: "bg_color"},
                {name: "login_required", default: false},
                {name: "order"}
            ]
        }
    };
};

export default plugins;