const abuse = props => {
    return {
        base: {
            entities: "گزارشات تخلف",
            entity: "گزارش تخلف",
            module: "abuse",
            path: "/abuse",
            model: {
                doc_type: {
                    title: "نوع گزارش",
                    type: "select",
                    items: [
                        {key: "service", value: "کسب و کار"},
                        {key: "comment", value: "نظر"}
                    ]
                },
                doc_id: {title: "مورد"},
                abuse_text: {title: "متن گزارش"},
            }
        },
        list: {
            page: props.page,
            fields: [
                {name: "doc_type"},
                {name: "doc_id"},
                {name: "abuse_text"},
                {name: "create_date", type: "date", title: "تاریخ"},
                {name: "create_date", type: "time", title: "زمان"},
            ],
            operations: ["remove"]
        }
    };
};

export default abuse;