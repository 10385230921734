const scores = props => {
    return {
        base: {
            entities: "امتیازات",
            entity: "امتیاز",
            module: "scores",
            path: "/scores",
            model: {
                type: {title: "نوع"},
                name: {title: "نام"},
                title: {title: "عنوان"},
                score: {title: "امتیاز"},
            }
        },
        list: {
            page: props.page,
            fields: [
                {name: "type"},
                {name: "name"},
                {name: "title"},
                {name: "score"},
            ],
            operations: ["add", "edit", "remove"]
        },
        item: {
            id: props.id,
            fields: [
                {name: "type"},
                {name: "name"},
                {name: "title"},
                {name: "score"},
            ]
        }
    };
};

export default scores;