import React from "react";
import {createUseStyles} from "react-jss";

const useStyles = createUseStyles({
    loading: props => ({
        display: 'inline-block',
        width: `${props.width}px`,
        height: `${props.height}px`,
        verticalAlign: 'middle',
        '&:after': {
            content: '" "',
            display: 'block',
            width: `${props.width - 4}px`,
            height: `${props.height - 4}px`,
            border: '3px solid',
            borderRadius: '50%',
            margin: '0 auto',
            animation: '$loadingAnimation 1.2s linear infinite',
            borderColor: props.borderColors[props.theme],
        }
    }),
    '@keyframes loadingAnimation': {
        from: {transform: 'rotate(0deg)'},
        to: {transform: 'rotate(360deg)'}
    }
});

function Loading(props) {
    let styleProps = {
        ...props,
        ...{
            borderColors: {
                'dark': '#5695f9 transparent #5695f9 transparent',
                'light': '#fff transparent #fff transparent'
            }
        }
    };
    const classes = useStyles(styleProps);

    return <div className={classes.loading} />;
}

Loading.defaultProps = {
    theme: 'dark',
    width: 20,
    height: 20
};

export default Loading;