import React, {useEffect, useState} from 'react';
import List from "../components/list/index";
import {complexFloors} from "../models/";
import Item from "../components/item";
import {xhr} from "../components";

export default function ComplexFloors(props) {
    const complex_id = props.match.params.complex;
    const [entities, setEntities] = useState({});
    const [pathname, setPathname] = useState(props.location ? props.location.pathname : '');

    const add = () => {
        setPathname("/complex_floors/add/" + complex_id);
    };

    const edit = id => {
        window.location = `/complex_floors/edit/${complex_id}/${id}`;
    };

    useEffect(() => {
        new xhr({
            url: 'services',
            data: complex_id
        }).GetOne(complex => {
            if(complex){
                setEntities(complexFloors({
                    complex_id,
                    complex_name: complex.title,
                    page: props.match.params.page,
                    id: props.match.params.id,
                    add,
                    edit
                }));
            }else{
                // TODO: show error
            }
        });
    }, []);

    return (
        <div>
            {entities.base ?
                pathname.indexOf("add") > -1 || pathname.indexOf("edit") > -1 ?
                    <Item
                        props={{
                            base: entities.base,
                            item: entities.item
                        }}
                    />
                    :
                    <List
                        props={{
                            base: entities.base,
                            list: entities.list
                        }}
                    />
                : ''
            }
        </div>
    );
}