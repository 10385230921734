const crm = props => {
    return {
        base: {
            entities: "تماس با کاربران",
            entity: "تماس",
            module: "crm",
            path: "/crm",
            model: {
                subject_id: {
                    title: "موضوع",
                    type: "select_data",
                    foreign: {
                        module: "crm_subjects",
                        path: "crm_subjects",
                        field: "id",
                        result: ["title"]
                    }
                },
                service_id: {
                    title: "کسب و کار",
                    type: "picker",
                    module: "services",
                    path: "businesses",
                    caption: "service_title",
                    other_fields: [
                        {name: "title", as: "service_title"},
                        {name: "phone", as: "phone"}
                    ]
                },
                service_title: {title: "کسب و کار"},
                operator_id: {title: "اپراتور"},
                operator_name: {title: "اپراتور"},
                contact_person: {title: "نام مخاطب"},
                phone: {title: "تلفن"},
                status: {
                    title: "وضعیت",
                    type: "radio",
                    items: [
                        {key: "unknown", value: "نامشخص"},
                        {key: "open", value: "برقرار"},
                        {key: "closed", value: "تعطیل"},
                        {key: "changed", value: "تغییر کرده"},
                    ]
                },
                comment: {title: "توضیحات"},
            }
        },
        list: {
            page: props.page,
            fields: [
                {name: "subject_id"},
                {name: "service_id"},
                {name: "contact_person"},
                {name: "operator_name"},
                {name: "phone"},
                {name: "status"},
                {name: "comment", max_length: 100},
            ],
            operations: ["add", "edit", "remove"]
        },
        item: {
            id: props.id,
            fields: [
                {name: "service_id"},
                {name: "subject_id", default: "60435cf7c794982ba5d163bc"},
                {name: "phone"},
                {name: "contact_person"},
                {name: "status"},
                {name: "comment", type: "textarea", rows: 5},
            ]
        }
    };
};

export default crm;