const tags = props => {
    return {
        base: {
            entities: "تگ ها",
            entity: "تگ",
            module: "tags",
            path: "/tags",
            confirm_field: "confirmed",
            model: {
                image: {title: "تصویر", type: "image", width: 600, height: 400},
                name: {title: "نام"},
                default: {title: "پیشفرض", type: "checkbox"},
            }
        },
        list: {
            page: props.page,
            fields: [
                {name: "image"},
                {name: "name"},
                {name: "default"},
            ],
            search: [
                {name: "name"}
            ],
            operations_style: {width: "170px"},
            operations: ["add", "edit", "remove", "confirm"]
        },
        item: {
            id: props.id,
            fields: [
                {name: "image"},
                {name: "name"},
                {name: "default"},
            ]
        }
    };
};

export default tags;