import React from "react";
import config from "../config";
import pages from "../pages";
import Cookies from "../components/cookies";
import LocalStorage from "../components/localstorage";

export const Context = React.createContext({});
Context.displayName = "MainContext";

export default class MainProvider extends React.Component {

    constructor(props) {
        super(props);
        const CookieObj = new Cookies();
        let token = CookieObj.getCookie('token');

        let badgeItem = localStorage.getItem('badgeItem');
        if(badgeItem){
            try {
                badgeItem = JSON.parse(badgeItem);
            } catch (e) {
                badgeItem = {};
            }
        }else{
            badgeItem = {};
        }

        this.state = {
            auth: !!token,
            user_id: CookieObj.getCookie('UID'),
            config,
            pages,
            setValue: this.setValue,
            logout: this.logout,
            fullName: this.fullName,
            locale: localStorage.getItem('locale') || "fa",
            setLocale: this.setLocale,
            badgeItem
        };
    }

    setLocale = locale => {
        this.setState({locale});
        localStorage.setItem('locale', locale);
        window.location.reload();
    }

    setValue = val => {
        this.setState(val);
    };

    logout = () => {
        this.setState({
            auth: false,
            user_id: null
        });
        const CookieObj = new Cookies();
        CookieObj.removeCookieBatch(['token', 'UID', 'UNAME', 'log_token']);
        const LS = new LocalStorage();
        LS.removeItem('report');
        LS.removeItem('baseURL');
        LS.removeItem('allowed');
        LS.removeItem('badgeItem');
        LS.removeItem('locale');
        window.location = '/login';
    };

    fullName = (first, last) => {
        first = first.trim();
        last = last.trim();
        if(!first && !last) return '';
        return first ? first + ' ' + last : last;
    };

    render() {
        return (
            <Context.Provider value={this.state}>
                {this.props.children}
            </Context.Provider>
        );
    }
}
