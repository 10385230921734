const claims = props => {
    return {
        base: {
            entities: "درخواست های مالکیت",
            entity: "درخواست مالکیت",
            module: "claims",
            path: "/claims",
            model: {
                user_id: {title: "کاربر"},
                name: {},
                family: {},
                service_id: {title: "کسب و کار"},
                title: {},
            }
        },
        list: {
            page: props.page,
            fields: [
                {
                    name: "user_id",
                    type: "function",
                    value: {
                        func: props.linkWithIdAndTitle,
                        params: [{static: 'users'}, 'user_id', [
                            'name',
                            {static: ' '},
                            'family'
                        ]]
                    }
                },
                {
                    name: "service_id",
                    type: "function",
                    value: {
                        func: props.linkWithIdAndTitle,
                        params: [{static: 'businesses'}, 'service_id', 'title']
                    }
                },
                {name: "create_date", type: "date", title: "تاریخ"},
                {name: "create_date", type: "time", title: "زمان"},
            ],
            custom_operations: [
                {
                    className: "warning",
                    caption: "تأیید",
                    click: {
                        func: props.functions.claim, // TODO: test this on the test server!
                        params: ["id", "service_id"]
                    }
                }
            ],
            operations_style: {width: '150px'},
            operations: ["remove"]
        },
    };
};

export default claims;