const categories = props => {
    return {
        base: {
            entities: "دسته بندی ها",
            entity: "دسته بندی",
            module: "categories",
            path: "/categories",
            model: {
                image: {title: "تصویر", type: "simple_image"},
                name: {title: "نام"},
                parent_id: {
                    title: "پدر",
                    type: "picker",
                    module: "categories",
                    foreign: {
                        module: "categories",
                        path: "/categories",
                        field: "id",
                        result: ["name"],
                    },
                },
                type: {
                    title: "نوع",
                    type: "select",
                    items: [
                        {key: "private", value: "خصوصی"},
                        {key: "public", value: "عمومی"}
                    ]
                },
                wander: {title: 'دور دور', type: "checkbox"},
                default: {title: "پیشفرض", type: "checkbox"}
            },
            picker: {
                params: ["name"],
                caption: ["name"],
                fields: [
                    {name: "image"},
                    {name: "name"}
                ]
            }
        },
        list: {
            page: props.page,
            fields: [
                {name: "image"},
                {name: "name"},
                {name: "parent_id", type: "foreign"},
                {name: "type"},
                {name: "wander"},
                {name: "default"}
            ],
            search: [
                {name: "name"}
            ],
            operations: ["add", "edit", "remove"]
        },
        item: {
            id: props.id,
            fields: [
                {name: "image"},
                {name: "name"},
                {name: "parent_id"},
                {name: "type"},
                {name: "wander"},
                {name: "default"}
            ]
        }
    };
};

export default categories;