const sponsor_packages = props => {
    return {
        base: {
            entities: "پکیج های تبلیغاتی",
            entity: "پکیج تبلیغاتی",
            module: "sponsor_packages",
            path: "/sponsor_packages",
            model: {
                title: {title: "عنوان"},
                days: {title: "تعداد روز"},
                price: {title: "قیمت (تومان)"},
                mc_price: {title: "قیمت (میزکوین)"},
                type: {
                    title: "نوع",
                    type: "radio",
                    items: [
                        {key: "list", value: "لیست"},
                        {key: "banner", value: "بنر"}
                    ]
                },
                category_id: {
                    title: "دسته بندی",
                    type: "picker",
                    module: "categories",
                    path: "categories",
                    caption: ""
                },
                city_id: {
                    title: "شهر",
                    type: "picker",
                    module: "cities",
                    path: "cities",
                    caption: ""
                },
                position: {
                    title: "موقعیت",
                    type: "select",
                    items: [
                        {key: 1, value: "اول"},
                        {key: 2, value: "دوم"},
                        {key: 3, value: "سوم"},
                        {key: 4, value: "چهارم"},
                        {key: 5, value: "پنجم"},
                    ]
                }
            }
        },
        list: {
            page: props.page,
            fields: [
                {name: "title"},
                {name: "category_id"},
                {name: "city_id"},
                {name: "position"},
                {name: "days"},
                {name: "price", type: "formatted_number"},
                {name: "mc_price", type: "formatted_number"},
                {name: "type"}
            ],
            operations: ["add", "edit", "remove"]
        },
        item: {
            id: props.id,
            fields: [
                {name: "title"},
                {name: "category_id", default: ""},
                {name: "city_id", default: ""},
                {name: "position"},
                {name: "days"},
                {name: "price"},
                {name: "mc_price"},
                {name: "type"}
            ]
        }
    };
};

export default sponsor_packages;