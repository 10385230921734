const campaigns = props => {
    return {
        base: {
            entities: "کمپین ها",
            entity: "کمپین",
            module: "campaigns",
            path: "/campaigns",
            model: {
                name: {title: "نام"},
                qr_code: {title: "کد"},
                visit_count: {title: "آمار"},
                description: {title: "توضیحات"},
            }
        },
        list: {
            page: props.page,
            fields: [
                {name: "name"},
                {name: "qr_code"},
                {name: "visit_count"},
                {name: "description"},
            ],
            operations: ["add", "edit", "remove"]
        },
        item: {
            id: props.id,
            fields: [
                {name: "name"},
                {name: "qr_code"},
                {name: "visit_count"},
                {name: "description"},
            ]
        }
    };
};

export default campaigns;